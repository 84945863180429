@use '../core/functions';

.mad {
    .mad-action-menu {
        --mad-tool-tip__offset: #{functions.mad-size(20)};

        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        .mad-action-menu__button {
            z-index: 0;
        }

        .mad-action-menu__tool-tip {
            z-index: 1;
        }
    }
}
