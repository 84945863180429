@use '../core/elevation';
@use '../core/functions';
@use '../core/typography';

.mad {
    .mad-tip {
        --mad-width: var(--mad-tip__width, auto);

        --mad-color: var(--mad-neutral-d-800);
        --mad-background-color: var(--mad-neutral-d-300);
        --mad-spike-size: #{functions.mad-size(8)};

        position: relative;
        width: var(--mad-width);
    }

    .mad-tip__body {
        @include elevation.mad-elevation('m');

        position: relative;
        color: var(--mad-color);
        background-color: var(--mad-background-color);
        border-radius: functions.mad-size(4);
        z-index: 1;
    }

    .mad-tip__text {
        @include typography.mad-text('s');

        padding: functions.mad-size(8);
    }

    .mad-tip__spike {
        --mad-local-spike-size: calc(
            var(--mad-spike-size) + #{functions.mad-size(2)}
        ); // add oversize because of blitting
        --mad-local-spike-offset: calc(-2 * var(--mad-spike-size));
        --mad-local-spike-offset2: calc(50% - var(--mad-local-spike-size));

        position: absolute;
        border-style: solid;
        border-color: transparent;
        border-width: var(--mad-local-spike-size);
        transform: translate3d(0%, 0%, 0%);
        z-index: 1;
    }

    .mad-tip {
        &.mad--top {
            > .mad-tip__spike {
                bottom: var(--mad-local-spike-offset);
                left: var(--mad-local-spike-offset2);
                border-top-color: var(--mad-background-color);
            }
        }

        &.mad--right {
            > .mad-tip__spike {
                top: var(--mad-local-spike-offset2);
                left: var(--mad-local-spike-offset);
                border-right-color: var(--mad-background-color);
            }
        }

        &.mad--bottom {
            > .mad-tip__spike {
                top: var(--mad-local-spike-offset);
                left: var(--mad-local-spike-offset2);
                border-bottom-color: var(--mad-background-color);
            }
        }

        &.mad--left {
            > .mad-tip__spike {
                top: var(--mad-local-spike-offset2);
                right: var(--mad-local-spike-offset);
                border-left-color: var(--mad-background-color);
            }
        }
    }
}
