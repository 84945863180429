@use 'flexboxgrid-extensions-dimensions' as flex-variables;

@mixin column-flex-padding-setup {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}
$columns: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12);
$screen-breakpoint-names: (xwgapp, 1080p, 1440p, 4k);
$screen-breakpoint-widths: (
    flex-variables.$xwgapp-min-width,
    flex-variables.$d_1080p-min-width,
    flex-variables.$d_1440p-min-width,
    flex-variables.$d_4k-min-width
);

@for $i from 1 through length($screen-breakpoint-names) {
    $breakpoint-name: nth($screen-breakpoint-names, $i);
    $breakpoint-width: nth($screen-breakpoint-widths, $i);

    @media only screen and (min-width: $breakpoint-width) {
        .col-#{$breakpoint-name} {
            @include column-flex-padding-setup;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            -ms-flex-preferred-size: 0;
            flex-basis: 0;
            max-width: 100%;
        }

        $column-count: length($columns);
        @each $column in $columns {
            .col-#{$breakpoint-name}-#{$column} {
                @include column-flex-padding-setup;

                $value: percentage($column / $column-count);
                -ms-flex-preferred-size: $value;
                flex-basis: $value;
                max-width: $value;
            }

            $offset-column: $column - 1;
            .col-#{$breakpoint-name}-offset-#{$offset-column} {
                @include column-flex-padding-setup;

                $value: percentage($offset-column / $column-count);
                margin-left: $value;
            }
        }

        .start-#{$breakpoint-name} {
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            text-align: start;
        }

        .center-#{$breakpoint-name} {
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            text-align: center;
        }

        .end-#{$breakpoint-name} {
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
            text-align: end;
        }

        .top-#{$breakpoint-name} {
            -webkit-box-align: start;
            -ms-flex-align: start;
            align-items: flex-start;
        }

        .middle-#{$breakpoint-name} {
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }

        .bottom-#{$breakpoint-name} {
            -webkit-box-align: end;
            -ms-flex-align: end;
            align-items: flex-end;
        }

        .around-#{$breakpoint-name} {
            -ms-flex-pack: distribute;
            justify-content: space-around;
        }

        .between-#{$breakpoint-name} {
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
        }

        .first-#{$breakpoint-name} {
            -webkit-box-ordinal-group: 0;
            -ms-flex-order: -1;
            order: -1;
        }

        .last-#{$breakpoint-name} {
            -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
            order: 1;
        }
    }
}

$all-size-names: (xs, sm, md, lg, xwgapp, 1080p, 1440p, 4k);
$all-min-widths: (
    flex-variables.$xs-min-width,
    flex-variables.$sm-min-width,
    flex-variables.$md-min-width,
    flex-variables.$lg-min-width,
    flex-variables.$xwgapp-min-width,
    flex-variables.$d_1080p-min-width,
    flex-variables.$d_1440p-min-width,
    flex-variables.$d_4k-min-width
);
$all-max-widths: (
    flex-variables.$xs-max-width,
    flex-variables.$sm-max-width,
    flex-variables.$md-max-width,
    flex-variables.$lg-max-width,
    flex-variables.$xwgapp-max-width,
    flex-variables.$d_1080p-max-width,
    flex-variables.$d_1440p-max-width,
    flex-variables.$d_4k-max-width
);

@for $i from 1 through length($all-size-names) {
    $size-name: nth($all-size-names, $i);
    .visible-#{$size-name} {
        display: none !important;
    }
}

@for $i from 1 through length($all-size-names) {
    $size-name: nth($all-size-names, $i);
    $size-min-width: nth($all-min-widths, $i);
    $size-max-width: nth($all-max-widths, $i);
    @media (min-width: $size-min-width) and (max-width: $size-max-width) {
        .visible-#{$size-name} {
            display: block !important;
        }

        .row.visible-#{$size-name} {
            display: flex !important;
            display: -webkit-flex !important;
            display: -ms-flexbox !important;
            display: -webkit-box !important;
            display: flex !important;
        }

        table.visible-#{$size-name} {
            display: table !important;
        }

        tr.visible-#{$size-name} {
            display: table-row !important;
        }

        th.visible-#{$size-name},
        td.visible-#{$size-name} {
            display: table-cell !important;
        }
    }
}

@for $i from 1 through length($all-size-names) {
    $size-name: nth($all-size-names, $i);
    $size-min-width: nth($all-min-widths, $i);
    $size-max-width: nth($all-max-widths, $i);

    @media (min-width: $size-min-width) and (max-width: $size-max-width) {
        .hidden-#{$size-name} {
            display: none !important;
        }
    }
}
