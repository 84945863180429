@use '../core/functions';
@use '../core/typography';
@use '../core/utils';

.mad {
    .mad-option-button {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        text-align: left;

        border: 2px solid transparent;
        background-color: transparent;

        transition: all 0.2s ease-out;

        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;

        &:focus,
        &.mad--focus {
            @include utils.mad-focus;
        }

        &:hover,
        &.mad--hover {
            transition-duration: 0s;
        }

        &:active,
        &.mad--active {
            @include utils.mad-active();
        }

        &:disabled,
        &.mad-disabled {
            opacity: var(--mad-disabled__opacity);
        }

        // sizing
        & {
            min-height: functions.mad-size(32);
            padding: functions.mad-size(4) functions.mad-size(8);
        }

        // theming
        & {
            --mad-background-color: transparent;
            --mad-hover-background-color: var(--mad-neutral-d-200);
            --mad-active-background-color: var(--mad-neutral-d-300);

            --mad-color: var(--mad-neutral-d-600);
            --mad-hover-color: var(--mad-neutral-d-700);
            --mad-active-color: var(--mad-neutral-d-800);

            color: var(--mad-color);
            background-color: var(--mad-background-color);

            &:not(:disabled) {
                &:focus,
                &:hover {
                    --mad-color: var(--mad-hover-color);
                    --mad-background-color: var(--mad-hover-background-color);
                }

                &:active {
                    --mad-color: var(--mad-active-color);
                    --mad-background-color: var(--mad-active-background-color);
                }
            }
        }
    }

    .mad-option-button__item {
        display: flex;
        align-items: center;
        justify-content: stretch;
    }

    .mad-option-button__icon {
        position: relative;
        flex: 0 0 auto;

        margin-right: functions.mad-size(8);
    }

    .mad-option-button__label {
        @include typography.mad-text('s');

        flex: 1 1 auto;
    }

    .mad-option-button__action {
        position: relative;
        flex: 0 0 auto;

        margin-left: functions.mad-size(16);
    }

    .mad-option-button__description {
        @include typography.mad-text('xs');

        --mad-weight: 400;

        margin-top: functions.mad-size(4);
    }
}
