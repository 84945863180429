@use 'functions';

@mixin mad-elevation($size: 'm') {
    @if $size == 'xs' {
        box-shadow:
            0 functions.mad-size(1) functions.mad-size(3) 0 var(--mad-shadow__color-a),
            0 functions.mad-size(1) functions.mad-size(2) 0 var(--mad-shadow__color-b);
    } @else if $size == 's' or not $size {
        box-shadow:
            0 functions.mad-size(4) functions.mad-size(6) functions.mad-size(-1) var(--mad-shadow__color-a),
            0 functions.mad-size(2) functions.mad-size(4) functions.mad-size(-1) var(--mad-shadow__color-b);
    } @else if $size == 'm' {
        box-shadow:
            0 functions.mad-size(10) functions.mad-size(15) functions.mad-size(3) var(--mad-shadow__color-a),
            0 functions.mad-size(4) functions.mad-size(6) functions.mad-size(-2) var(--mad-shadow__color-b);
    } @else if $size == 'l' {
        box-shadow:
            0 functions.mad-size(20) functions.mad-size(25) functions.mad-size(-5) var(--mad-shadow__color-a),
            0 functions.mad-size(10) functions.mad-size(10) functions.mad-size(-5) var(--mad-shadow__color-b);
    } @else if $size == 'xl' {
        box-shadow:
            0 functions.mad-size(30) functions.mad-size(35) functions.mad-size(-7) var(--mad-shadow__color-a),
            0 functions.mad-size(20) functions.mad-size(20) functions.mad-size(-8) var(--mad-shadow__color-b);
    }
}
