/* You can add global styles to this file, and also import other style unassignedFiles */
@use 'core';

@font-face {
    font-family: 'Inter';
    src: url('/assets/fonts/Inter-VariableFont.woff2') format('woff2');
    font-weight: 1 900; //Needed for Safari to work.
}

html,
body {
    min-height: 100%;
    width: 100%;
    margin: 0;
    font-size: var(--main-font-size);
    color: var(--mad-neutral-d-560);
    background-color: var(--mad-neutral-d-210);
}

@media (min-height: 995px) {
    html {
        font-size: 16px;
    }
}

.page-container {
    height: 100%;
}

.page-wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.list-container {
    min-width: 200px;

    ul {
        list-style-type: none;
        margin: 8px 0;
        padding: 0;

        li {
            border: 1px solid var(--mad-neutral-d-240);
            border-bottom: 0;

            &:hover {
                background-color: var(--mad-neutral-d-101);
                cursor: pointer;
            }

            &:last-of-type {
                border-bottom: 1px solid var(--mad-neutral-d-240);
            }

            .item-container {
                padding: 8px;
            }
        }
    }
}

.split-content-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .split-content {
        flex: 1;

        & > * {
            margin-bottom: 24px;
        }
    }
}

.modal-content {
    display: flex;
    flex: 1 1 auto;

    &.has-loader {
        flex-direction: column;
    }

    .left {
        width: 25%;
    }

    .middle {
        width: 45%;
    }

    .right {
        width: 30%;
    }

    &.mad-modal__content {
        .total-column {
            min-width: 16rem;
            flex: 1;

            &.no-min-width {
                min-width: unset;
            }
        }
    }

    &:not(.mad-modal__content) {
        .modal-content-column {
            flex: 1;
            margin-left: var(--separator-margin);

            &:first-child,
            &.no-margin {
                margin-left: 0;
            }
        }

        .left,
        .middle,
        .right,
        .modal-content-column {
            .modal-content-row {
                display: flex;
                justify-content: space-between;
                margin-top: 10px;

                &:first-child,
                &.no-margin-top {
                    margin-top: 0;
                }

                &.warning-row {
                    margin-top: 20px;
                }

                > *:first-child:not(.modal-content-row-column) {
                    flex: 1;
                }
            }
        }

        .middle {
            margin-left: var(--separator-margin);
        }

        .right {
            margin-left: var(--separator-margin);
        }

        nz-date-picker,
        nz-month-picker {
            width: 100%;

            .ant-calendar-picker {
                width: 100%;
            }
        }
    }
}

.modal-content:not(.mad-modal__content),
.split-content-container {
    .separator {
        margin-left: var(--separator-margin);
        border-right: 1px solid var(--mad-neutral-d-365);

        &.transparent {
            border-color: transparent;
        }
    }

    .horizontal-separator {
        background-color: var(--mad-neutral-d-365);
        height: 1px;
        margin: 10px 0;
        width: 100%;
    }

    .label-with-popover {
        display: flex;
    }
}

.container-box {
    border: 1px solid var(--main-font-size);
}

.inner-card-box {
    border: 1px solid var(--mad-neutral-d-560);
    padding: var(--inner-card-box-padding);

    .short-model-card-subtitle,
    .short-model-card-inputs-wrapper,
    .short-model-card-footer,
    .title {
        font-size: 16px;
        min-height: 24px;
        color: var(--mad-neutral-d-560);
        margin-bottom: var(--inner-card-box-padding);
    }
}

.flex-basis-70 {
    flex-basis: 80%;
    width: 80%;
}

.flex-basis-20 {
    flex-basis: 20%;
    width: 20%;
}

.flex-basis-100 {
    flex-basis: 100%;
    width: 100%;
}

.column-actions {
    margin: 8px 0;
}

.item-actions {
    margin-top: 8px;
}

.scrollable-content-inset-box-shadow {
    box-shadow: inset 0 -30px 20px -30px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: inset 0 -30px 20px -30px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: inset 0 -30px 20px -30px rgba(0, 0, 0, 0.4);
}

.horizontal-separator {
    margin: 16px 0;
    height: 1px;
    background: var(--mad-neutral-d-530);
    display: flex;
}

.bar,
.top-border {
    &.tranche-0 {
        background-color: var(--mad-neutral-d-455);
    }

    &.tranche-1 {
        background-color: var(--mad-neutral-d-430);
    }

    &.tranche-2 {
        background-color: var(--mad-neutral-d-390);
    }

    &.tranche-3 {
        background-color: var(--mad-neutral-d-345);
    }

    &.tranche-4 {
        background-color: var(--mad-neutral-d-300);
    }

    &.dummy-tranche {
        border: 1px solid var(--mad-neutral-d-999);
        border-left: 0;
        background-color: var(--mad-neutral-d-100);
    }
}

.collaterals-column,
.early-withdrawals-column,
.documents-page-column {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    .header-wrapper {
        padding: 8px 0;
        font-size: 15px;

        .icon-wrapper,
        .actions-wrapper {
            margin: 0 5px;
        }

        .title-wrapper {
            flex: 1 1 auto;
            font-weight: bold;
        }
    }

    .scroll-container-wrapper {
        flex: 1 1 auto;

        .padded-content {
            position: relative;
            height: 100%;

            .scrollable-content {
                position: absolute;
                height: 100%;
                width: calc(100% - 2 * var(--separator-margin));
                display: flex;
                justify-content: center;

                .items-wrapper {
                    width: 100%;
                }
            }

            .manual-inset-shadow {
                position: absolute;
                width: 100%;
                bottom: 0;
                left: 0;
                height: 30px;
                background-color: transparent;
                pointer-events: none;
            }
        }
    }

    .footer-wrapper {
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.range-separator {
    width: 4px;
    border-top: 2px solid var(--mad-neutral-d-530);
}

.right {
    .mad-title-bar {
        display: none;
    }
}

.number-input-align-right {
    .mad-input__input {
        text-align: right;
    }
}

.label-padding-0 {
    .mad-field__label {
        padding-left: 0;
    }
}

.mad {
    .font-weight--bold {
        font-weight: bold;
    }

    // Hypo-dossier overrides
    .mad-auto-scroll::-webkit-scrollbar-track {
        margin: 0;
    }

    p {
        margin-bottom: 0;
    }
}

@media only screen and (min-width: core.$d_1080p-max-width-px + 1) {
    $menu-width: 242px;
    $content-width: calc(#{core.$d_1080p-max-width-px} - #{$menu-width});
    $project-create-width: calc(0.25 * #{$content-width});
    $project-list-width: calc(#{$content-width} - #{$project-create-width});

    .projects-create {
        flex-basis: $project-create-width;
    }

    .project-list-content-wrapper {
        flex-grow: 1;
        max-width: 100%;

        .project-list-header,
        .recent-projects__item {
            max-width: calc(0.66 * #{$project-list-width});
        }
    }

    .cockpit-view__column {
        max-width: calc(0.5 * #{$content-width});
    }

    .project-header-wrapper {
        max-width: $content-width;
    }

    .offers-page-content {
        max-width: calc(#{$content-width} - var(--project-page-container-margin));
    }

    .project-documents-wrapper {
        max-width: $content-width;
    }
}
