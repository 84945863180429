@use 'core/functions';

.mad {
    --mad-primary__font: Inter, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    --mad-secondary__font: var(--mad-primary__font);

    --mad-title__weight: 600;
    --mad-title__font: var(--mad-primary__font);

    --mad-text__weight: 400;
    --mad-text__weight--bold: 600;

    --mad-focus__width: #{functions.mad-size(3)};
    --mad-hover__width: #{functions.mad-size(3)};

    --mad-disabled__opacity: 0.25;
    --mad-disabled-button__opacity: 0.5;

    --mad__text-font: var(--mad-secondary__font);
    --mad__text-weight: var(--mad-text__weight);
}
