.mad {
    .mad-flex {
        display: flex;
    }

    .mad-flex-grow {
        flex-grow: 1;
        flex-shrink: 0;
    }

    .mad-flex-shrink {
        flex-grow: 0;
        flex-shrink: 1;
    }

    .mad-flex-dynamic {
        flex-grow: 1;
        flex-shrink: 1;
    }

    .mad-flex-static {
        flex-grow: 0;
        flex-shrink: 0;
    }

    .mad-flex-fit {
        flex: 1 0 auto;
    }

    .mad-flex-fill {
        flex: 1 1 auto;
    }

    .mad-flex-stretch {
        justify-content: stretch;
        align-items: stretch;
    }

    @each $name, $prop in ('align': 'align-items', 'justify': 'justify-content') {
        @each $key,
            $value
                in (
                    'start': 'flex-start',
                    'middle': 'center',
                    'end': 'flex-end',
                    'fill': 'stretch',
                    'baseline': 'baseline'
                )
        {
            .mad-flex-#{$name}--#{$key} {
                #{$prop}: #{$value};
            }
        }
    }

    .mad-flex {
        &.mad--inline {
            display: inline-flex;
        }

        &:not(.mad--vertical) {
            &.mad--middle {
                align-items: center;
            }
        }

        &.mad--vertical {
            flex-direction: column;

            &.mad--middle {
                justify-content: center;
            }
        }
    }

    .mad-flex-container {
        display: flex;
        flex-direction: column;
    }
}
