@use 'config';
@use 'functions';

@mixin mad-title($size: null) {
    --mad-color: var(--mad-title__color);
    --mad-weight: var(--mad-title__weight);
    --mad-font: var(--mad-title__font);

    font-family: var(--mad-font);
    color: var(--mad-color);
    font-weight: var(--mad-weight);

    @if $size {
        @include mad-title-size($size);
    }
}

@mixin mad-text($size: null) {
    --mad-color: var(--mad-text__color);
    --mad-weight: var(--mad-text__weight);

    color: var(--mad-color);
    font-weight: var(--mad-weight);

    @if $size {
        @include mad-text-size($size);
    }
}

@mixin mad-title-size($key) {
    $font-size: functions.mad-size($key, config.$mad-title-sizes);
    $line-height: functions.mad-size($key, config.$mad-title-line-heights);

    font-size: $font-size;
    line-height: $line-height;
}

@mixin mad-text-size($key) {
    $font-size: functions.mad-size($key, config.$mad-text-sizes);
    $line-height: functions.mad-size($key, config.$mad-text-line-heights);

    font-size: $font-size;
    line-height: $line-height;
}
