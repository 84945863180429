:root {
    --main-font-size: 14px;

    .mad {
        --border-width: 2px;
        --loading-icon-font-size: 24px;
        --page-title-font-size: 20px;
        --drawer-z-index: 21;
        --sticky-bar-z-index: 22;
    }
}
