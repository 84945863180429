@use 'functions';
@use 'margin';

@mixin mad-default($selector, $test) {
    @if ($test) {
        &,
        &#{$selector} {
            @content;
        }
    } @else {
        &#{$selector} {
            @content;
        }
    }
}

@mixin mad-revert() {
    all: revert;
    box-sizing: border-box; // NOTE: keep global box model change
}

@mixin mad-unset() {
    all: unset;
    box-sizing: border-box; // NOTE: keep global box model change
}

@mixin mad-clickable($hover: true, $focus: true, $active: true) {
    @include mad-unset();

    cursor: pointer;
    outline: none;

    @if $hover {
        &:hover,
        &.mad--hover {
            @include mad-hover();
        }
    }

    @if $focus {
        &:focus,
        &.mad--focus {
            @include mad-focus();
        }
    }

    @if $active {
        &:active,
        &.mad--active {
            @include mad-active();
        }
    }
}

@mixin mad-focus {
    outline: none;
    box-shadow: 0 0 0 var(--mad-focus__width) var(--mad-focus__color);
}

@mixin mad-hover {
    transition-duration: 0s !important; // skip transitions on hover for instant hover effect
    box-shadow: 0 0 0 var(--mad-hover__width) var(--mad-hover__color);
}

@mixin mad-disabled {
    opacity: var(--mad-disabled__opacity);
}

@mixin mad-active {
    transform: translate3d(0, functions.mad-size(2), 0);
}

@mixin mad-flex-gap($gap: 16) {
    > * {
        @include margin.mad-margin($gap, 'right');

        &:last-child {
            margin-right: 0;
        }
    }
}

@mixin ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

$display-types: (
    block,
    contents,
    flex,
    flow-root,
    grid,
    inherit,
    initial,
    inline,
    inline-block,
    inline-flex,
    inline-grid,
    inline-table,
    list-item,
    none,
    table,
    table-caption,
    table-cell,
    table-column,
    table-column-group,
    table-header-group,
    table-footer-group,
    table-row,
    table-row-group,
    unset
);
@mixin display-type-modifiers {
    @each $display-type in $display-types {
        .display-#{$display-type} {
            display: $display-type;
        }

        .display-#{$display-type}--important {
            display: $display-type !important;
        }
    }
}

$position-types: (absolute, fixed, inherit, initial, relative, revert, static, sticky, unset);
@mixin position-type-modifiers {
    @each $position-type in $position-types {
        .position-#{$position-type} {
            position: $position-type;
        }

        .position-#{$position-type}--important {
            position: $position-type !important;
        }
    }
}

$flex-direction-types: (row, column);
@mixin flex-direction-modifiers {
    @each $flex-direction-type in $flex-direction-types {
        .flex-direction-#{$flex-direction-type} {
            flex-direction: $flex-direction-type;
        }
    }
}

@mixin xs-viewport {
    @media only screen and (min-width: 30em) {
        @content;
    }
}

@mixin sm-viewport {
    @media only screen and (min-width: 48em) {
        @content;
    }
}

@mixin md-viewport {
    @media only screen and (min-width: 64em) {
        @content;
    }
}

@mixin lg-viewport {
    @media only screen and (min-width: 75em) {
        @content;
    }
}

@mixin include-breakpoint($viewport-size) {
    @if $viewport-size == xs {
        @include xs-viewport {
            @content;
        }
    } @else if $viewport-size == sm {
        @include sm-viewport {
            @content;
        }
    } @else if $viewport-size == md {
        @include md-viewport {
            @content;
        }
    } @else if $viewport-size == lg {
        @include lg-viewport {
            @content;
        }
    }
}

$viewport-types: (xs, sm, md, lg);
@mixin viewport-modifiers {
    @each $viewport-size in $viewport-types {
        @include include-breakpoint($viewport-size) {
            .#{$viewport-size}-flex-column {
                flex-direction: column;
            }

            .#{$viewport-size}-flex-row {
                flex-direction: row;
            }

            .#{$viewport-size}-align-items-center {
                align-items: center;
            }

            .#{$viewport-size}-align-items-flex-start {
                align-items: flex-start;
            }

            .#{$viewport-size}-align-items-flex-end {
                align-items: flex-end;
            }

            .#{$viewport-size}-align-items-center-important {
                align-items: center !important;
            }

            .#{$viewport-size}-full-width {
                width: 100%;
            }

            .#{$viewport-size}-width-50 {
                width: 50%;
            }

            .#{$viewport-size}-full-height {
                height: 100%;
            }
        }
    }
}

$spacing-types: (
    margin,
    margin-top,
    margin-bottom,
    margin-left,
    margin-right,
    padding,
    padding-top,
    padding-bottom,
    padding-left,
    padding-right
);
@mixin spacing-modifiers {
    @each $spacing-type in $spacing-types {
        .no-#{$spacing-type} {
            #{$spacing-type}: 0;
        }

        .no-#{$spacing-type}-important {
            #{$spacing-type}: 0 !important;
        }
    }
}

@mixin disabledControl {
    opacity: 0.5;
}
