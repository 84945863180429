@use '../core/config';
@use '../core/padding';
@use 'sass:map';

.mad {
    @each $size in map.keys(config.$mad-padding-sizes) {
        .mad-padding-#{$size} {
            @include padding.mad-padding($size);
        }

        @each $side in top, right, bottom, left, vertical, horizontal {
            .mad-padding-#{$side}-#{$size} {
                @include padding.mad-padding($size, $side);
            }
        }
    }
}
