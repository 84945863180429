@use 'functions';

$mad-grid-columns: 12 !default;

@mixin mad-grid($gap: 16) {
    --mad-gap: #{functions.mad-size($gap)};

    display: grid;
    grid-template-columns: repeat($mad-grid-columns, 1fr);
    grid-gap: var(--mad-gap);
}

@mixin mad-grid-column-start($offset) {
    grid-column-start: #{$offset + 1};
}

@mixin mad-grid-column-span($value) {
    grid-column-end: span $value;
}
