@use 'sass:map';
@use '../core/config';
@use '../core/components/button';
@use '../core/functions';
@use '../core/utils';

.mad {
    &.mad-theme--light {
        --mad-default-color: var(--mad-neutral-d-701);
        --mad-hover-color: var(--mad-neutral-d-575);
        --mad-disabled-color: var(--mad-default-color);
        --mad-selected-background-color: var(--mad-primary-500);
    }

    &.mad-theme--dark {
        --mad-default-color: var(--mad-primary-725);
        --mad-hover-color: var(--mad-neutral-d-999);
        --mad-disabled-color: var(--mad-neutral-d-625);
        --mad-selected-background-color: var(--mad-primary-500);
    }

    .mad-icon-button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 auto;

        border: functions.mad-size(2) solid transparent;
        background-color: transparent;

        margin: 0;
        padding: 0;
        overflow: hidden;
        border-radius: 50%;

        transition: all 0.2s ease-out;

        cursor: pointer;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;

        &:focus,
        &.mad--focus {
            outline: none;
        }

        &:disabled,
        &.mad--disabled {
            &:not(.mad--readonly) {
                opacity: var(--mad-disabled-button__opacity);
            }

            cursor: not-allowed;
        }

        &.mad--busy {
            .mad-icon {
                animation: 1s linear 0s infinite running mad-spin;
            }
        }

        .mad-icon {
            svg {
                margin: 0 !important; // NOTE: fix for nasty jiggle effect in chrome
            }
        }

        & {
            --mad-default-background-color: transparent;

            --mad-hover-background-color: var(--mad-default-background-color);

            --mad-focus-color: var(--mad-neutral-d-575);
            --mad-focus-background-color: var(--mad-default-background-color);

            --mad-active-color: var(--mad-neutral-d-701);
            --mad-active-background-color: transparent;

            --mad-selected-color: var(--mad-neutral-d-000);

            --mad-color: var(--mad-default-color);
            --mad-background-color: var(--mad-default-background-color);

            color: var(--mad-color);
            background-color: var(--mad-background-color);

            &:not(:disabled):not(.mad--disabled) {
                &:focus,
                &.mad--focus {
                    --mad-color: var(--mad-focus-color);
                    --mad-background-color: var(--mad-focus-background-color);
                }

                &:hover,
                &.mad--hover {
                    --mad-color: var(--mad-hover-color);
                    --mad-background-color: var(--mad-hover-background-color);
                }

                &:active,
                &.mad--active {
                    --mad-color: var(--mad-active-color);
                    --mad-background-color: var(--mad-active-background-color);

                    @include utils.mad-active();
                }
            }

            &:disabled,
            &.mad--disabled {
                color: var(--mad-disabled-color);
                background-color: transparent;
            }
        }

        @each $size in map.keys(config.$mad-sizes) {
            &.mad-icon-button--#{$size} {
                @include button.mad-icon-button-size($size);
            }
        }

        &.mad--primary {
            --mad-default-color: var(--mad-primary-500);
            --mad-hover-color: var(--mad-primary-600);
            --mad-active-color: var(--mad-primary-700);
        }

        &.mad--success {
            --mad-default-color: var(--mad-success-600);
            --mad-hover-color: var(--mad-success-700);
            --mad-active-color: var(--mad-success-800);
        }

        &.mad--danger {
            --mad-default-color: var(--mad-danger-500);
            --mad-hover-color: var(--mad-danger-600);
            --mad-active-color: var(--mad-danger-700);
        }

        &.mad--subtle {
            --mad-default-color: var(--mad-neutral-d-400);
            --mad-hover-color: var(--mad-neutral-d-500);
            --mad-active-color: var(--mad-neutral-d-600);
        }

        &.mad--selected {
            --mad-color: var(--mad-selected-color) !important;
            --mad-background-color: var(--mad-selected-background-color) !important;
        }

        &.mad--warning {
            --mad-default-color: var(--mad-warning-500);
            --mad-hover-color: var(--mad-warning-600);
            --mad-active-color: var(--mad-warning-700);
        }

        &:disabled {
            cursor: default;
        }
    }
}
