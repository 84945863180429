@use 'core/functions';
@use 'core/list';

.mad {
    .mad-list {
        @include list.mad-list();
    }

    .mad-list {
        @each $size in (16, 12, 8, 4, 2, 0) {
            &.mad-list--#{$size} {
                --mad-gap: #{functions.mad-size($size)};
            }
        }
    }
}
