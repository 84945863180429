@use 'sass:map';
@use 'icon';
@use '../functions';

@mixin mad-button-size($key) {
    $font-size: map.get(
        (
            'm': 14,
            'l': 20
        ),
        $key
    );
    $height: map.get(
        (
            'm': 32,
            'l': 40
        ),
        $key
    );
    $horizontal-padding: map.get(
        (
            'm': 4,
            'l': 8
        ),
        $key
    );
    $vertical-padding: map.get(
        (
            'm': 12,
            'l': 16
        ),
        $key
    );
    $border-radius: map.get(
        (
            'm': 4,
            'l': 6
        ),
        $key
    );
    $icon-size: map.get(
        (
            'm': 16,
            'l': 20
        ),
        $key
    );
    $icon-margin: map.get(
        (
            'm': 8,
            'l': 12
        ),
        $key
    );

    font-size: functions.mad-size($font-size);
    min-height: functions.mad-size($height);
    padding: functions.mad-size($horizontal-padding) functions.mad-size($vertical-padding);
    border-radius: functions.mad-size($border-radius);

    .mad-icon {
        @include icon.mad-icon-size($icon-size);

        &:first-child {
            margin-right: functions.mad-size($icon-margin);
        }

        &:last-child {
            margin-left: functions.mad-size($icon-margin);
        }
    }
}

@mixin mad-icon-button-size($key) {
    $value: functions.mad-size($key);

    width: 2.75rem;
    height: 2.75rem;

    margin: calc((2.75rem - $value) / 2 * -1);

    .mad-icon {
        @include icon.mad-icon-size($key);
    }
}
