@use '../core/components/button';
@use '../core/functions';
@use '../core/utils';

.mad {
    &.mad-theme--light {
        --mad-default-button-color: var(--mad-neutral-d-000);
        --mad-default-button-background-color: var(--mad-neutral-d-702);

        --mad-default-button-hover-color: var(--mad-neutral-d-000);
        --mad-default-button-hover-background-color: var(--mad-neutral-d-550);

        --mad-default-button-active-color: var(--mad-neutral-d-000);
        --mad-default-button-active-background-color: var(--mad-neutral-d-702);

        --mad-default-button-disabled-color: var(--mad-neutral-d-000);
        --mad-default-button-disabled-background-color: var(--mad-neutral-d-450);
    }

    &.mad-theme--dark {
        --mad-default-button-color: var(--mad-neutral-d-999);
        --mad-default-button-background-color: var(--mad-neutral-d-525);

        --mad-default-button-hover-color: var(--mad-neutral-d-425);
        --mad-default-button-hover-background-color: var(--mad-neutral-d-999);

        --mad-default-button-active-color: var(--mad-neutral-d-999);
        --mad-default-button-active-background-color: var(--mad-neutral-d-525);

        --mad-default-button-disabled-color: var(--mad-neutral-d-999);
        --mad-default-button-disabled-background-color: var(--mad-neutral-d-625);

        .mad-button {
            &.mad--primary {
                --mad-default-color: var(--mad-info-400);
                --mad-default-background-color: var(--mad-neutral-d-999);
                --mad-hover-color: var(--mad-info-999);
                --mad-hover-background-color: var(--mad-primary-575);
                --mad-active-color: var(--mad-info-400);
                --mad-active-background-color: var(--mad-neutral-d-999);
            }

            &.mad--accompanying {
                --mad-default-color: var(--mad-primary-725);
                --mad-hover-color: var(--mad-neutral-d-999);
                --mad-active-color: var(--mad-primary-725);
                --mad-default-border-color: var(--mad-primary-725);
                --mad-hover-border-color: var(--mad-neutral-d-999);
                --mad-active-border-color: var(--mad-primary-725);
                --mad-default-background-color: transparent;
                --mad-hover-background-color: transparent;
                --mad-active-background-color: transparent;

                &:disabled,
                &.mad--disabled {
                    --mad-disabled-color: var(--mad-neutral-d-625);
                    --mad-disabled-background-color: transparent;
                    --mad-disabled-border-color: var(--mad-neutral-d-625);
                }
            }

            &.mad-button--text {
                --mad-default-color: var(--mad-primary-725);
                --mad-default-background-color: transparent;
                --mad-hover-color: var(--mad-neutral-d-999);
                --mad-hover-background-color: transparent;
                --mad-active-color: var(--mad-neutral-d-725);
                --mad-active-background-color: transparent;

                &:disabled,
                &.mad--disabled {
                    color: var(--mad-neutral-d-625);
                    background-color: transparent;
                }
            }
        }
    }

    .mad-button {
        @include button.mad-button-size('m');

        @each $key, $size in ('l': 'large') {
            &.mad--#{$size} {
                @include button.mad-button-size($key);
            }
        }
    }

    .mad-button {
        --mad-default-color: var(--mad-default-button-color);
        --mad-default-background-color: var(--mad-default-button-background-color);
        --mad-default-border-color: transparent;

        --mad-hover-color: var(--mad-default-button-hover-color);
        --mad-hover-background-color: var(--mad-default-button-hover-background-color);
        --mad-hover-border-color: transparent;

        --mad-active-color: var(--mad-default-button-active-color);
        --mad-active-background-color: var(--mad-default-button-active-background-color);
        --mad-active-border-color: transparent;

        --mad-disabled-color: var(--mad-default-button-disabled-color);
        --mad-disabled-background-color: var(--mad-default-button-disabled-background-color);
        --mad-disabled-border-color: transparent;
        --mad-default-button-background-color: var(--mad-neutral-d-624);

        display: inline-flex;
        align-items: center;

        border: functions.mad-size(2) solid var(--mad-default-border-color);
        background-color: var(--mad-default-background-color);
        color: var(--mad-default-color);

        font-family: var(--mad-secondary__font);
        font-weight: 600;
        text-decoration: none !important;

        transition: all 0.2s ease-out;

        cursor: pointer;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;

        &:focus,
        &.mad--focus {
            @include utils.mad-focus;
        }

        &:disabled,
        &.mad--disabled {
            &:not(.mad--readonly) {
                opacity: var(--mad-disabled-button__opacity);
            }

            cursor: not-allowed;
        }

        &.mad--busy {
            .mad-icon {
                animation: 1s linear 0s infinite running mad-spin;
            }
        }

        &.mad-button--visibility-not-transitioned {
            transition:
                all 0.2s ease-out,
                visibility 0s;
        }

        &:not(:disabled):not(.mad--disabled) {
            &:focus,
            &.mad--focus,
            &:hover,
            &.mad--hover {
                color: var(--mad-hover-color);
                background-color: var(--mad-hover-background-color);
                border-color: var(--mad-hover-border-color);
            }

            &:active,
            &.mad--active {
                color: var(--mad-active-color);
                background-color: var(--mad-active-background-color);
                border-color: var(--mad-active-border-color);

                @include utils.mad-active();
            }
        }

        &:disabled,
        &.mad--disabled {
            color: var(--mad-disabled-color);
            background-color: var(--mad-disabled-background-color);
            border-color: var(--mad-disabled-border-color);
        }

        &.mad--primary {
            --mad-default-color: var(--mad-neutral-d-000);
            --mad-default-background-color: var(--mad-primary-600);
            --mad-hover-background-color: var(--mad-primary-500);
            --mad-active-background-color: var(--mad-primary-600);
        }

        &.mad--primary-light {
            --mad-default-color: var(--mad-primary-700);
            --mad-default-background-color: var(--mad-primary-100);
            --mad-hover-color: var(--mad-primary-800);
            --mad-hover-background-color: var(--mad-primary-200);
            --mad-active-color: var(--mad-primary-900);
            --mad-active-background-color: var(--mad-primary-300);
        }

        &.mad--accompanying {
            --mad-default-color: var(--mad-primary-600);
            --mad-hover-color: var(--mad-primary-500);
            --mad-active-color: var(--mad-primary-600);
            --mad-default-border-color: var(--mad-primary-600);
            --mad-hover-border-color: var(--mad-primary-500);
            --mad-active-border-color: var(--mad-primary-600);
            --mad-default-background-color: var(--mad-neutral-d-000);
            --mad-hover-background-color: var(--mad-neutral-d-000);
            --mad-active-background-color: var(--mad-neutral-d-000);

            &:disabled,
            &.mad--disabled {
                --mad-disabled-color: var(--mad-neutral-d-450);
                --mad-disabled-background-color: var(--mad-neutral-d-000);
                --mad-disabled-border-color: var(--mad-neutral-d-450);
            }
        }

        &.mad--success {
            --mad-default-color: var(--mad-neutral-d-000);
            --mad-default-background-color: var(--mad-success-600);
            --mad-hover-background-color: var(--mad-success-700);
            --mad-active-background-color: var(--mad-success-800);
        }

        &.mad--danger {
            --mad-default-color: var(--mad-neutral-d-000);
            --mad-default-background-color: var(--mad-danger-600);
            --mad-hover-background-color: var(--mad-danger-700);
            --mad-active-background-color: var(--mad-danger-800);
        }

        &.mad--subtle {
            --mad-default-color: var(--mad-neutral-d-600);
            --mad-default-background-color: var(--mad-neutral-d-200);
            --mad-hover-color: var(--mad-neutral-d-700);
            --mad-hover-background-color: var(--mad-neutral-d-300);
            --mad-active-color: var(--mad-neutral-d-800);
            --mad-active-background-color: var(--mad-neutral-d-400);
        }

        &.mad--warning {
            --mad-default-color: var(--mad-neutral-d-000);
            --mad-default-background-color: var(--mad-warning-500);
            --mad-hover-background-color: var(--mad-warning-600);
            --mad-active-color: var(--mad-neutral-d-000);
            --mad-active-background-color: var(--mad-warning-700);
        }

        &.mad-button--ghost {
            --mad-default-color: var(--mad-neutral-d-700);
            --mad-default-background-color: transparent;
            --mad-default-border-color: var(--mad-neutral-d-600);

            &.mad--primary {
                --mad-default-color: var(--mad-primary-600);
                --mad-default-border-color: var(--mad-primary-500);
                --mad-hover-background-color: var(--mad-primary-600);
            }

            &.mad--success {
                --mad-default-color: var(--mad-success-600);
                --mad-default-border-color: var(--mad-success-500);
                --mad-hover-background-color: var(--mad-success-600);
            }

            &.mad--danger {
                --mad-default-color: var(--mad-danger-600);
                --mad-default-border-color: var(--mad-danger-500);
                --mad-hover-background-color: var(--mad-danger-600);
            }
        }

        &.mad-button--text {
            --mad-default-color: var(--mad-neutral-d-701);
            --mad-default-background-color: transparent;
            --mad-hover-color: var(--mad-neutral-d-575);
            --mad-hover-background-color: transparent;
            --mad-active-color: var(--mad-neutral-d-701);
            --mad-active-background-color: transparent;

            &:disabled,
            &.mad--disabled {
                color: var(--mad-neutral-d-701);
                background-color: transparent;
            }

            &.mad--primary {
                --mad-default-color: var(--mad-primary-600);
                --mad-hover-color: var(--mad-primary-700);
                --mad-active-color: var(--mad-primary-800);
            }

            &.mad--success {
                --mad-default-color: var(--mad-success-600);
                --mad-hover-color: var(--mad-success-700);
                --mad-active-color: var(--mad-success-800);
            }

            &.mad--danger {
                --mad-default-color: var(--mad-danger-600);
                --mad-hover-color: var(--mad-danger-700);
                --mad-active-color: var(--mad-danger-800);
            }
        }

        &.mad--large {
            .mad-icon {
                &:first-child,
                &:last-child {
                    margin-left: 0;
                    margin-right: 0;
                }
            }

            > sfx-icon {
                &:first-child {
                    .mad-icon {
                        margin-right: 0.75rem;
                    }
                }

                &:last-child {
                    .mad-icon {
                        margin-left: 0.75rem;
                    }
                }
            }
        }

        &.mad--small {
            font-size: 0.75rem;
            min-height: 1.5rem;
            padding: 0.3125rem 0.5rem;
            border-radius: 0.1875rem;
            line-height: 0.875rem;

            .mad-icon,
            .mad-icon svg {
                width: 0.875rem;
                height: 0.875rem;
            }
        }

        .mad-icon {
            &:first-child,
            &:last-child {
                margin-left: 0;
                margin-right: 0;
            }
        }

        > sfx-icon {
            &:first-child {
                .mad-icon {
                    margin-right: 0.5rem;
                }
            }

            &:last-child {
                .mad-icon {
                    margin-left: 0.5rem;
                }
            }
        }

        &.mad--primary__inverted {
            --mad-default-background-color: var(--mad-primary-400);
        }

        &.mad--busy {
            .mad-icon.button-suffix-icon {
                animation: none;
            }
        }
    }
}
