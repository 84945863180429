:root {
    --mad-neutral-d-210: rgb(242, 242, 242);
    --mad-neutral-d-560: rgb(112, 112, 112);
}

.mad.mad-theme--light,
.mad-theme--light {
    // non-standard colors
    --mad-neutral-d-101: rgb(250, 250, 250);
    --mad-neutral-d-205: rgb(246, 242, 242);
    --mad-neutral-d-215: rgb(240, 240, 240);
    --mad-neutral-d-230: rgb(238, 234, 234);
    --mad-neutral-d-235: rgb(237, 243, 246);
    --mad-neutral-d-240: rgb(237, 237, 237);
    --mad-neutral-d-250: rgb(236, 236, 236);
    --mad-neutral-d-255: rgb(233, 233, 233);
    --mad-neutral-d-270: rgb(230, 230, 230);
    --mad-neutral-d-275: rgb(230, 228, 228);
    --mad-neutral-d-280: rgb(228, 228, 228);
    --mad-neutral-d-290: rgb(226, 226, 226);
    --mad-neutral-d-315: rgb(219, 219, 219);
    --mad-neutral-d-320: rgb(217, 217, 217);
    --mad-neutral-d-330: rgb(213, 213, 213);
    --mad-neutral-d-345: rgb(208, 208, 208);
    --mad-neutral-d-360: rgb(203, 203, 203);
    --mad-neutral-d-365: rgb(201, 201, 201);
    --mad-neutral-d-384: rgb(194, 194, 194);
    --mad-neutral-d-390: rgb(192, 192, 192);
    --mad-neutral-d-395: rgb(181, 181, 181);
    --mad-neutral-d-420: rgb(179, 179, 179);
    --mad-neutral-d-421: rgb(178, 178, 178);
    --mad-neutral-d-425: rgb(185, 185, 185);
    --mad-neutral-d-430: rgb(176, 176, 176);
    --mad-neutral-d-450: rgb(160, 174, 180);
    --mad-neutral-d-455: rgb(160, 160, 160);
    --mad-neutral-d-470: rgb(134, 152, 159);
    --mad-neutral-d-525: rgb(132, 132, 132);
    --mad-neutral-d-530: rgb(128, 128, 128);
    --mad-neutral-d-540-a-38: rgba(123, 161, 175, 0.38);
    --mad-neutral-d-550: rgb(122, 122, 122);
    --mad-neutral-d-560-a-25: rgba(112, 112, 112, 0.25);
    --mad-neutral-d-575: rgb(111, 110, 110);
    --mad-neutral-d-624: rgb(90, 90, 90);
    --mad-neutral-d-625: rgb(89, 89, 89);
    --mad-neutral-d-670: rgb(68, 68, 68);
    --mad-neutral-d-701: rgb(55, 55, 55);
    --mad-neutral-d-702: rgb(54, 54, 54);
    --mad-neutral-d-999: rgb(0, 0, 0);

    --mad-primary-80: rgb(230, 252, 255);
    --mad-primary-85: rgb(226, 247, 255);
    --mad-primary-210: rgb(163, 243, 255);
    --mad-primary-220: rgb(183, 221, 236);
    --mad-primary-225: rgb(182, 230, 249);
    --mad-primary-250: rgb(148, 205, 227);
    --mad-primary-310: rgb(120, 220, 252);
    --mad-primary-370: rgb(75, 177, 210);
    --mad-primary-440: rgb(34, 115, 141);
    --mad-primary-455: rgb(27, 173, 228);
    --mad-primary-460: rgb(24, 144, 255);
    --mad-primary-485: rgb(2, 148, 202);
    --mad-primary-490: rgb(0, 148, 203);
    --mad-primary-575: rgb(0, 154, 212);
    --mad-primary-725: rgb(0, 109, 148);
    --mad-primary-950: rgb(0, 25, 35);

    --mad-info-175: rgb(194, 240, 252);

    --mad-success-250: rgb(181, 245, 189);
    --mad-success-450: rgb(0, 216, 135);

    --mad-danger-550: rgb(255, 0, 0);

    // https://www.colorbox.io/#steps=9#hue_start=0#hue_end=0#hue_curve=linear#sat_start=0#sat_end=0#sat_curve=linear#sat_rate=130#lum_start=100#lum_end=10#lum_curve=easeInOutSine#minor_steps_map=0
    --mad-neutral-d-000-rgb: 255, 255, 255;
    --mad-neutral-d-100-rgb: 251, 251, 251;
    --mad-neutral-d-200-rgb: 246, 246, 246;
    --mad-neutral-d-300-rgb: 224, 224, 224;
    --mad-neutral-d-400-rgb: 188, 188, 188;
    --mad-neutral-d-500-rgb: 141, 141, 141;
    --mad-neutral-d-600-rgb: 94, 94, 94;
    --mad-neutral-d-700-rgb: 57, 57, 57;
    --mad-neutral-d-800-rgb: 35, 35, 35;
    --mad-neutral-d-900-rgb: 26, 26, 26;

    @each $weight in ('000', '100', '200', '300', '400', '500', '600', '700', '800', '900') {
        --mad-neutral-d-#{$weight}: rgb(var(--mad-neutral-d-#{$weight}-rgb));
    }

    // https://www.colorbox.io/#steps=9#hue_start=196#hue_end=196#hue_curve=linear#sat_start=2#sat_end=90#sat_curve=easeOutSine#sat_rate=130#lum_start=100#lum_end=20#lum_curve=easeOutQuad#lock_hex=00aeef#minor_steps_map=0
    --mad-primary-050: rgba(248, 253, 255);
    --mad-primary-100: rgba(217, 248, 255);
    --mad-primary-200: rgba(185, 242, 255);
    --mad-primary-300: rgba(119, 222, 255);
    --mad-primary-400: rgba(54, 199, 253);
    --mad-primary-500: rgb(0, 174, 239);
    --mad-primary-600: rgba(0, 148, 203);
    --mad-primary-700: rgba(0, 116, 159);
    --mad-primary-800: rgba(0, 78, 106);
    --mad-primary-900: rgba(0, 37, 51);

    // https://www.colorbox.io/#steps=9#hue_start=170#hue_end=180#hue_curve=easeInQuad#sat_start=4#sat_end=90#sat_curve=easeOutSine#sat_rate=130#lum_start=100#lum_end=20#lum_curve=easeOutQuad#lock_hex=88E996#minor_steps_map=0
    --mad-secondary-050: rgb(242, 255, 253);
    --mad-secondary-100: rgb(231, 254, 246);
    --mad-secondary-200: rgb(221, 253, 236);
    --mad-secondary-300: rgb(178, 245, 199);
    --mad-secondary-400: rgb(136, 233, 150);
    --mad-secondary-500: rgb(73, 212, 116);
    --mad-secondary-600: rgb(30, 183, 103);
    --mad-secondary-700: rgb(16, 145, 100);
    --mad-secondary-800: rgb(5, 100, 83);
    --mad-secondary-900: rgb(0, 51, 51);

    --mad-success-100: rgb(231, 252, 215);
    --mad-success-200: rgb(202, 249, 177);
    --mad-success-300: rgb(162, 238, 135);
    --mad-success-400: rgb(123, 222, 102);
    --mad-success-500: rgb(70, 201, 56);
    --mad-success-600: rgb(42, 172, 40);
    --mad-success-700: rgb(28, 144, 35);
    --mad-success-800: rgb(17, 116, 32);
    --mad-success-900: rgb(10, 96, 30);

    --mad-info-100: rgba(217, 248, 255);
    --mad-info-200: rgba(185, 242, 255);
    --mad-info-300: rgba(119, 222, 255);
    --mad-info-400: rgba(54, 199, 253);
    --mad-info-500: rgb(0, 174, 239);
    --mad-info-600: rgba(0, 148, 203);
    --mad-info-700: rgba(0, 116, 159);
    --mad-info-800: rgba(0, 78, 106);
    --mad-info-900: rgba(0, 37, 51);

    --mad-warning-100: rgb(255, 244, 204);
    --mad-warning-200: rgb(255, 231, 153);
    --mad-warning-300: rgb(255, 213, 102);
    --mad-warning-400: rgb(255, 197, 63);
    --mad-warning-500: rgb(255, 169, 0);
    --mad-warning-600: rgb(219, 137, 0);
    --mad-warning-700: rgb(183, 108, 0);
    --mad-warning-800: rgb(147, 82, 0);
    --mad-warning-900: rgb(122, 64, 0);

    --mad-danger-100: rgb(255, 228, 213);
    --mad-danger-150: rgb(255, 211, 211);
    --mad-danger-200: rgb(255, 194, 171);
    --mad-danger-300: rgb(255, 152, 129);
    --mad-danger-400: rgb(255, 113, 97);
    --mad-danger-500: rgb(255, 49, 45);
    --mad-danger-600: rgb(219, 32, 45);
    --mad-danger-700: rgb(183, 22, 47);
    --mad-danger-800: rgb(147, 14, 46);
    --mad-danger-900: rgb(122, 8, 45);

    // --- color variables

    --mad-white-rgb: 255, 255, 255;
    --mad-black-rgb: 0, 0, 0;

    --mad-primary__color: var(--mad-primary-500);
    --mad-secondary__color: var(--mad-secondary-500);

    --mad-primary__color-rgb: 0, 174, 239;
    --mad-secondary__color-rgb: 73, 212, 116;

    --mad-title__color: var(--mad-neutral-d-800);
    --mad-text__color: var(--mad-neutral-d-900);

    --mad-focus__color: var(--mad-primary-200);
    --mad-hover__color: var(--mad-neutral-d-300);

    --mad-highlight__color: var(--mad-secondary-300);

    --mad-shadow__color-a: rgba(0, 37, 51, 0.1);
    --mad-shadow__color-b: rgba(0, 37, 51, 0.06);

    --mad-base-container-background-color: var(--mad-neutral-d-000);

    --nz-default-font-color: rgba(26, 26, 26, 0.65);
    --nz-date-picker-disabled-selection-color: rgba(0, 0, 0, 0.1);
    --nz-date-picker-disabled-border-color: rgba(217, 217, 217, 0.5);
    --nz-date-picker-disabled-color: rgba(245, 245, 245, 0.5);

    --expired-color: var(--mad-warning-700);
    --error-color: var(--mad-danger-600);
    --mad-neutral-bg: var(--mad-neutral-d-200);
    --theme-main-tone: var(--mad-neutral-d-000);
}
