@use 'config';
@use 'functions';

// $size: value or abbr
// $side: top, right, bottom, left, horizontal, vertical

@mixin mad-margin($size: 16px, $side: null) {
    $value: #{functions.mad-size($size, config.$mad-margin-sizes)};

    @if not $side {
        margin: $value;
    } @else if $side == 'top' {
        margin-top: $value;
    } @else if $side == 'right' {
        margin-right: $value;
    } @else if $side == 'bottom' {
        margin-bottom: $value;
    } @else if $side == 'left' {
        margin-left: $value;
    } @else if $side == 'vertical' {
        margin-top: $value;
        margin-bottom: $value;
    } @else if $side == 'horizontal' {
        margin-right: $value;
        margin-left: $value;
    }
}
