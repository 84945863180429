.mad {
    .mad-auto-container {
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: stretch;
    }

    .mad-auto-container__body {
        flex: 1 1 auto;
        z-index: 0;
        overflow: auto;
    }

    .mad-auto-container__shadow-top,
    .mad-auto-container__shadow-bottom {
        position: absolute;
        right: 0;
        left: 0;
        height: 2rem;
        z-index: 1;
        pointer-events: none;
    }

    .mad-auto-container__shadow-top {
        top: 0;
        background-image: linear-gradient(180deg, var(--mad-shadow__color-a) 15%, transparent);
    }

    .mad-auto-container__shadow-bottom {
        bottom: 0;
        background-image: linear-gradient(0deg, var(--mad-shadow__color-a) 15%, transparent);
    }
}
