.mad {
    .mad-tool-tip {
        --mad-tip__width: var(--mad-tool-tip__width, auto);
        --mad-offset: var(--mad-tool-tip__offset, 1rem);

        --mad-hidden-offset: 1rem;
        --mad-local-neg-offset: calc(var(--mad-offset) * -1);

        position: absolute;
        transform: translate3d(0%, 0%, 0);
        visibility: visible;

        transition-property: all;
        transition-duration: 0.1s;
        transition-timing-function: ease-in;
        transition-delay: 0s;

        > .mad-tip {
            position: absolute;
        }

        &.mad--top {
            transform: translate3d(0%, -100%, 0);
            top: var(--mad-local-neg-offset);
            left: 50%;

            > .mad-tip {
                transform: translate3d(-50%, -100%, 0);
            }
        }

        &.mad--right {
            transform: translate3d(100%, 0%, 0);
            top: 50%;
            right: var(--mad-local-neg-offset);

            > .mad-tip {
                transform: translate3d(0%, -50%, 0);
            }
        }

        &.mad--bottom {
            transform: translate3d(0%, 100%, 0);
            bottom: var(--mad-local-neg-offset);
            left: 50%;

            > .mad-tip {
                transform: translate3d(-50%, 0%, 0);
            }
        }

        &.mad--left {
            transform: translate3d(-100%, 0%, 0);
            top: 50%;
            left: var(--mad-local-neg-offset);

            > .mad-tip {
                transform: translate3d(-100%, -50%, 0);
            }
        }

        &.mad--hidden {
            --mad-local-hidden-offset: calc(100% + var(--mad-hidden-offset));
            --mad-local-neg-hidden-offset: calc(var(--mad-local-hidden-offset) * -1);

            visibility: hidden;
            opacity: 0;

            transition-duration: 0.3s;
            transition-timing-function: ease-out;
            transition-delay: 0.2s;

            &.mad--top {
                transform: translate3d(0%, var(--mad-local-neg-hidden-offset), 0);
            }

            &.mad--right {
                transform: translate3d(var(--mad-local-hidden-offset), 0%, 0);
            }

            &.mad--bottom {
                transform: translate3d(0%, var(--mad-local-hidden-offset), 0);
            }

            &.mad--left {
                transform: translate3d(var(--mad-local-neg-hidden-offset), 0%, 0);
            }
        }
    }
}
