.mad {
    .mad--inverted-colors {
        --color: var(--theme-main-tone);

        .mad-title-bar * {
            color: var(--color);
        }

        .mad-field .mad-field__label {
            color: var(--color);
        }

        .mad-info__icon .mad-icon {
            --mad-color: var(--color);
        }

        .mad-info__separator {
            --border-color: var(--color);
        }

        .mad-info__content {
            .mad-info__title,
            .mad-info__subtitle,
            .mad-info__text {
                color: var(--color);
            }
        }
    }
}
