@mixin mad-card() {
    --mad-border-color: var(--mad-card__border-color, transparent);
    --mad-background-color: var(--mad-card__background-color, var(--mad-neutral-d-000));

    position: relative;
    background-color: var(--mad-background-color);
    border: 2px solid var(--mad-border-color);

    &.mad--dragover {
        border-style: dashed;
    }

    &.mad--selected {
        --mad-border-color: var(--mad-primary__color);
    }

    &.mad--success {
        --mad-border-color: var(--mad-success-500);
    }

    &.mad--info {
        --mad-border-color: var(--mad-info-500);
    }

    &.mad--warning {
        --mad-border-color: var(--mad-warning-500);
    }

    &.mad--error,
    &.mad--danger,
    &.mad--attention {
        --mad-border-color: var(--mad-danger-500);
    }
}
