@use 'core/components/card';
@use 'core/elevation';
@use 'core/functions';

.mad {
    .mad-group {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: stretch;
    }

    .mad-group__content {
        @include card.mad-card();
        @include elevation.mad-elevation('s');

        flex: 1 0 auto;
        padding: functions.mad-size(24);
        border-radius: functions.mad-size('xs');
    }
}
