@use '../core/config';
@use '../core/radius';
@use 'sass:map';

.mad {
    @each $size in map.keys(config.$mad-radius-sizes) {
        .mad-radius-#{$size} {
            @include radius.mad-radius($size);
        }

        @each $edge in top-left, top-right, bottom-right, bottom-left {
            .mad-radius-#{$edge}-#{$size} {
                @include radius.mad-radius($size, $edge);
            }
        }

        @each $edge,
            $edges
                in (
                    top: (
                            top-left,
                            top-right
                        ),
                    right: (
                            top-right,
                            bottom-right
                        ),
                    bottom: (
                            bottom-right,
                            bottom-left
                        ),
                    left: (
                            bottom-left,
                            top-left
                        )
                )
        {
            .mad-radius-#{$edge}-#{$size} {
                @each $edge in $edges {
                    @include radius.mad-radius($size, $edge);
                }
            }
        }
    }
}
