@use 'core/functions';

.mad {
    // NOTE: scrollbars in css are highly experimental
    .mad-auto-scroll {
        --mad-default-track-color: var(--mad-auto-scroll__default-track-color, var(--mad-neutral-d-100));
        --mad-hover-track-color: var(--mad-auto-scroll__hover-track-color, var(--mad-neutral-d-100));
        --mad-default-thumb-color: var(--mad-auto-scroll__default-thumb-color, var(--mad-neutral-d-300));
        --mad-hover-thumb-color: var(--mad-auto-scroll__hover-thumb-color, var(--mad-neutral-d-500));

        --mad-track-color: var(--mad-default-track-color);
        --mad-thumb-color: var(--mad-default-thumb-color);

        overflow-x: hidden;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: var(--mad-thumb-color) var(--mad-track-color);

        &::-webkit-scrollbar {
            width: functions.mad-size(16);
        }

        &::-webkit-scrollbar-track {
            border-radius: functions.mad-size(8);
            background-color: var(--mad-track-color);

            &:hover {
                --mad-track-color: var(--mad-hover-track-color);
            }
        }

        &::-webkit-scrollbar-thumb {
            border-radius: functions.mad-size(8);
            border: functions.mad-size(4) solid var(--mad-track-color);
            background-color: var(--mad-thumb-color);

            &:hover {
                --mad-track-color: var(--mad-hover-track-color);
                --mad-thumb-color: var(--mad-hover-thumb-color);
            }
        }
    }
}
