@use './ng-zorro.theme.css';
@use './styling';

@include styling.core-utils-display-type-modifiers;
@include styling.core-utils-flex-direction-modifiers;
@include styling.core-utils-position-type-modifiers;
@include styling.core-utils-viewport-modifiers;
@include styling.core-utils-spacing-modifiers;

.flex-center {
    justify-content: center;
    align-items: center;
}

.flex-align-start {
    align-items: flex-start;
}

.flex-align-self-to-start {
    align-self: flex-start;
}

.flex-row {
    flex-direction: row;
}

.flex-column {
    flex-direction: column;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--mad-neutral-d-560);
}

h1 {
    font-size: 28px;
}

h2 {
    font-size: var(--page-title-font-size);
}

p {
    margin-bottom: 0;
}

.cursor-pointer {
    cursor: pointer;
}

.ellipsis {
    @include styling.core-utils-ellipsis;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin-right: 0;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.capitalize {
    text-transform: capitalize;
}

.visibility-hidden {
    visibility: hidden;
}

.loading-icon {
    font-size: var(--loading-icon-font-size);
}

.error {
    color: var(--error-color);
}

.flex-grow {
    flex-grow: 1;
}

.mad-tool-tip {
    --mad-tool-tip__width: 180px;
}

.mad-field__info .mad-tool-tip {
    --mad-tool-tip__width: 220px;
}

.autocomplete-option {
    width: 100%;
}

.keep-text-format {
    white-space: pre-wrap;
}

.mad-option-button__label.disabled-label {
    color: var(--main-border-color);
}

textarea {
    &:active,
    &:focus {
        outline-color: var(--mad-focus-border-color);
    }
}

.flex-wrap {
    flex-wrap: wrap;
}

.text-align--justify {
    text-align: justify;
    text-justify: inter-word;
}

svg {
    &:not([width]) {
        width: 100%;
    }

    &:not([height]) {
        height: 100%;
    }
}
