@use '../config';
@use '../functions';

@mixin mad-icon($size: null) {
    --mad-color: var(--mad-icon__color, currentcolor);

    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    color: var(--mad-color);

    svg {
        fill: var(--mad-color);
    }

    @if $size {
        @include mad-icon-size($size);
    }
}

@mixin mad-icon-size($key) {
    $value: functions.mad-size($key, config.$mad-icon-sizes);

    width: $value;
    height: $value;

    svg {
        width: calc($value * 1.25); // NOTE: font awesome icons have a ratio of 1.25:1
        height: $value;

        margin: 0 calc(-1 * $value * 0.125);
    }
}
