@use '../core/functions';

.mad {
    .mad-modal {
        --mad-icon-color: var(--mad-primary__color);
        --mad-border-color: transparent;
        --mad-background-color: var(--mad-neutral-d-000);

        &.mad--error {
            --mad-border-color: var(--mad-danger-500);
            --mad-icon-color: var(--mad-danger-500);
        }

        &.mad--info {
            --mad-icon-color: var(--mad-info-500);
        }

        &.mad--warning {
            --mad-icon-color: var(--mad-warning-500);
        }

        &.mad--success {
            --mad-icon-color: var(--mad-success-500);
        }

        &.mad--danger,
        &.mad--error,
        &.mad--attention {
            --mad-icon-color: var(--mad-danger-500);
        }

        .mad-icon.mad-modal__icon {
            --mad-color: var(--mad-icon-color);

            margin-right: functions.mad-size(12);
        }
    }

    .mad-modal__shim {
        z-index: 0;
    }

    .mad-modal__body {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        z-index: 1;

        min-width: functions.mad-size(320);

        background-color: var(--mad-background-color);
        border: functions.mad-size(2) solid var(--mad-border-color);
    }

    .mad-modal__header {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        margin-bottom: functions.mad-size(20);
    }

    .mad-modal__status {
        margin-right: functions.mad-size(16);
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .mad-modal__close {
        //transform: translate(#{functions.mad-size(8)}, #{functions.mad-size(-12)});
        transform: translate(#{functions.mad-size(8)}, 0);
    }

    .mad-modal__content {
        flex: 1 0 auto;
        display: flex;
        justify-content: stretch;
        align-items: stretch;
    }

    .mad-modal.ant-modal {
        @extend .mad-modal !optional;

        .ant-modal-header {
            @extend .mad-modal__header !optional;
            margin-bottom: 0;
        }

        .ant-modal-content {
            border: none;
            @extend .mad-modal__body !optional;
            @extend .mad-elevation-xl !optional;
            @extend .mad-radius-xl !optional;
            @extend .mad-padding-24 !optional;
        }
    }

    .vertical-center-modal {
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-modal {
            top: 0;
        }
    }

    .ant-modal {
        width: 1000px;

        .ant-modal-header {
            border-bottom: 0;
            padding: 0;

            .ant-modal-title {
                flex: 1 1 auto;
            }
        }

        .ant-modal-body {
            padding: 0;
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
        }

        .ant-modal-content {
            border-radius: 0.5rem;
            padding: 1.5rem;
            border: 2px solid var(--mad-border-color);

            //A slight modification of the original box-shadow. Please note that the last effect's vertical offset is set to 0 (was 9px) to fix Safari issue.
            box-shadow:
                0 3px 6px -4px rgba(0, 0, 0, 0.12),
                0 8px 16px 0 rgba(0, 0, 0, 0.08),
                0 0 28px 8px rgba(0, 0, 0, 0.05);
        }

        .ant-modal-footer {
            border-top: 0;
            padding: 0;
            margin-top: 1.25rem;
        }
    }
}
