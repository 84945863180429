@mixin input-error-highlight($duration, $leftBorderColor: #ff0000, $rightBorderColor: #ff0000) {
    animation: error-highlight $duration;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;

    @keyframes error-highlight {
        0% {
            border-color: var(--error-color);
            border-left-color: $leftBorderColor;
            border-right-color: $rightBorderColor;
        }

        100% {
            border-color: inherit;
        }
    }
}

@mixin deep-input-error {
    ::ng-deep {
        &.input-error-3s {
            .ant-input,
            .ant-select-selection {
                @include input-error-highlight(1s, inherit);
            }

            .ant-input-group-addon {
                @include input-error-highlight(1s);
            }
        }
    }
}
