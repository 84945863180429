.mad {
    @for $index from 0 through 9 {
        .mad-level-#{$index} {
            z-index: $index;
        }
    }

    .mad-level-n-1 {
        z-index: -1;
    }
}
