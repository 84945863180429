@use '../core/components/input';
@use '../core/utils';

.ant-btn.ant-dropdown-trigger:not(:hover):not(:focus):not(:disabled),
.ant-modal,
.ant-collapse,
.ant-collapse-content,
.ant-dropdown-menu-item:not(.ant-dropdown-menu-item-selected):not(.ant-dropdown-menu-item-disabled),
.ant-select,
.ant-select-item:not(.ant-select-item-option-disabled),
.ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.ant-radio-wrapper,
.ant-picker-content th,
.ant-picker-content .ant-picker-cell-in-view,
date-table .ant-picker-content .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-content .ant-picker-cell-in-view.ant-picker-cell-selected.ant-picker-cell-disabled .ant-picker-cell-inner,
.ant-picker .ant-picker-input input {
    color: var(--nz-default-font-color);
}

.ant-btn,
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker {
    border-radius: 4px;
}

.ant-select {
    .ant-select-arrow svg {
        transition: transform 0.3s;
    }

    &.ant-select-open .ant-select-arrow .anticon-down svg {
        transform: rotate(180deg);
    }

    &.mad--error .ant-select-selector {
        border: 1px solid var(--mad-danger-500);
    }

    &.ant-select-disabled {
        @include utils.disabledControl;
    }
}

.ant-select-dropdown {
    padding: 0;

    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: var(--mad-primary-80);
    }

    // TODO: remove when new version of zorro is available with the fix https://github.com/NG-ZORRO/ng-zorro-antd/pull/7642
    .cdk-virtual-scroll-spacer {
        position: absolute;
        top: 0;
        width: 1px;
    }
}

.ant-dropdown {
    &.mad-auto-scroll {
        -webkit-box-shadow: 0 2px 8px rgb(0 37 51 / 10%);
        box-shadow: 0 2px 8px rgb(0 37 51 / 10%);
        border-radius: 4px;
        max-height: 250px;
    }

    .ant-dropdown-menu-item {
        display: flex;

        &:not(.ant-dropdown-menu-item-disabled):hover {
            background-color: var(--mad-primary-80);
        }

        &.ant-dropdown-menu-item-selected {
            color: var(--mad-primary-500);
            background-color: var(--mad-primary-80);
        }

        .ant-menu-title-content {
            display: flex;
        }
    }
}

.ant-modal .ant-modal-footer .ant-btn,
.ant-modal .ant-modal-confirm-btns .ant-btn {
    display: inline-flex;
    align-items: center;
    border: 0.125rem solid transparent;
    font-family: var(--mad-secondary__font);
    font-weight: 600;
    text-decoration: none !important;
    transition: all 0.2s ease-out;
    outline: none;
    -webkit-appearance: none;
    line-height: 1.15;
    margin: 0;

    font-size: 0.875rem;
    min-height: 2rem;
    padding: 0.25rem 0.75rem;
    border-radius: 0.25rem;
    box-shadow: none;
    text-shadow: none;

    &:not(:disabled):not(.mad--disabled) {
        color: var(--mad-neutral-d-701);
        background-color: transparent;

        &:hover,
        &:focus {
            color: var(--mad-neutral-d-575);
            background-color: transparent;
        }

        &:active {
            color: var(--mad-neutral-d-701);
            background-color: transparent;
        }

        &.ant-btn-primary {
            color: var(--mad-neutral-d-000);
            background-color: var(--mad-primary-600);

            &:hover,
            &:focus {
                background-color: var(--mad-primary-500);
            }

            &:active {
                background-color: var(--mad-primary-600);
            }
        }
    }

    &:disabled,
    &.mad--disabled {
        background-color: initial;
        opacity: var(--mad-disabled-button__opacity);

        &.ant-btn-primary {
            color: var(--mad-default-button-disabled-color);
            background: var(--mad-default-button-disabled-background-color);
        }
    }
}

.ant-modal.ant-modal-confirm.ant-modal-confirm-confirm .ant-modal-confirm-body .anticon {
    color: var(--mad-warning-500);
}

.ant-modal.ant-modal-confirm.ant-modal-confirm-error .ant-modal-confirm-body .anticon {
    color: var(--mad-danger-500);
}

sfx-input,
.app-input {
    label {
        color: var(--mad-neutral-d-560);
        font-size: var(--main-font-size);
        font-weight: normal;
    }

    .ant-input-group {
        color: var(--mad-neutral-d-560);

        .ant-input-group-addon {
            background-color: var(--mad-neutral-d-215);
        }

        .ant-input-group-addon,
        .ant-input {
            border-color: var(--mad-neutral-d-560);
        }
    }
}

::ng-deep {
    .input-error-3s {
        .ant-input-group,
        .ant-input {
            @include input.input-error-highlight(3s);
        }
    }
}

.input-error-3s::ng-deep {
    .ant-input-group,
    .ant-input {
        @include input.input-error-highlight(3s);
    }

    sfx-input::ng-deep {
        .ant-input-group,
        .ant-input {
            @include input.input-error-highlight(3s);
        }
    }

    input {
        border: red !important;
    }
}

.ant-calendar-input-wrap {
    display: none;
}

.ant-popover .ant-popover-content {
    border-radius: 0.25rem;
    line-height: 1.27;

    .ant-popover-arrow-content:before,
    .ant-popover-inner-content {
        background-color: var(--mad-primary-950);
        color: var(--mad-neutral-d-270);
    }

    .mad-auto-scroll {
        --mad-default-track-color: var(--mad-primary-950);
        --mad-hover-track-color: var(--mad-primary-950);
    }

    .ant-popover-inner,
    .ant-popover-inner-content {
        border-radius: 0.25rem;
    }

    .ant-popover-inner-content {
        font-size: 12px;

        p {
            margin-bottom: 1rem;
        }

        table {
            margin: 0.25rem 1rem 0.25rem 0.25rem;

            thead {
                th {
                    padding-top: 0.85rem;
                    padding-bottom: 0.85rem;
                }
            }

            tbody {
                tr {
                    border-top: solid 1px var(--mad-neutral-d-270);

                    &:first-child {
                        border-color: var(--mad-neutral-d-270);
                    }

                    td {
                        padding-top: 0.65rem;
                        padding-bottom: 0.65rem;
                        vertical-align: top;
                        min-width: 110px;

                        &:not(:last-child) {
                            padding-right: 2.625rem;
                        }
                    }
                }
            }
        }
    }
}

a {
    color: var(--mad-primary-500);

    &:hover {
        color: var(--mad-primary-400);
    }
}

.mad--inverted-colors .ant-checkbox-wrapper {
    span {
        color: var(--color);
    }

    .ant-checkbox-inner {
        background-color: var(--mad-primary-600);
        border-radius: 3px;
        border: 1px solid var(--color);

        &:hover {
            border-radius: 3px;
            border: 1px solid var(--color);
        }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: var(--color);
        border-color: var(--color);

        &::after {
            border-color: var(--mad-primary-600);
        }

        &:hover {
            border: 1px solid var(--color);
        }
    }

    .ant-checkbox-indeterminate .ant-checkbox-inner {
        background-color: var(--color);
        border-color: var(--color);

        &::after {
            background-color: var(--mad-primary-600);
        }

        &:hover {
            border: 1px solid var(--color);
        }
    }

    .ant-checkbox-checked::after {
        border-color: var(--color);
        border-radius: 3px;
    }
}
