@use 'config';
@use 'functions';

// $size: value or abbr
// $side: top, right, bottom, left, horizontal, vertical

@mixin mad-padding($size: 16px, $side: null) {
    $value: #{functions.mad-size($size, config.$mad-padding-sizes)};

    @if not $side {
        padding: $value;
    } @else if $side == 'top' {
        padding-top: $value;
    } @else if $side == 'right' {
        padding-right: $value;
    } @else if $side == 'bottom' {
        padding-bottom: $value;
    } @else if $side == 'left' {
        padding-left: $value;
    } @else if $side == 'vertical' {
        padding-top: $value;
        padding-bottom: $value;
    } @else if $side == 'horizontal' {
        padding-right: $value;
        padding-left: $value;
    }
}
