@use 'core/typography';

.mad {
    .mad-title {
        @include typography.mad-title;

        &.mad-title--xxl {
            font-size: 2rem;
            line-height: 2.5rem;
        }

        &.mad-title--xl {
            font-size: 1.75rem;
            line-height: 2.25rem;
        }

        &.mad-title--l {
            font-size: 1.5rem;
            line-height: 2rem;
        }

        &.mad-title--ml {
            font-size: 1.375rem;
            line-height: 1.75rem;
        }

        &.mad-title--m {
            font-size: 1.25rem;
            line-height: 1.5rem;
        }

        &.mad-title--sm {
            font-size: 1.125rem;
            line-height: 1.375rem;
        }

        &.mad-title--s {
            font-size: 1rem;
            line-height: 1.25rem;
        }

        &.mad-title--xs {
            font-size: 0.875rem;
            line-height: 1.125rem;
        }
    }

    .mad-text {
        @include typography.mad-text;

        &.mad-text--l {
            font-size: 1.25rem;
            line-height: 1.5rem;
        }

        &.mad-text--ml {
            font-size: 1.125rem;
            line-height: 1.375rem;
        }

        &.mad-text--m {
            font-size: 1rem;
            line-height: 1.25rem;
        }

        &.mad-text--s {
            font-size: 0.875rem;
            line-height: 1.125rem;
        }

        &.mad-text--xs {
            font-size: 0.75rem;
            line-height: 1rem;
        }

        &.mad-text-relative--xs {
            font-size: 0.75em;
        }
    }

    .mad-article {
        > * {
            &:first-child {
                padding-top: 0 !important;
            }

            &:last-child {
                padding-bottom: 0 !important;
            }
        }

        .mad-title {
            &.mad-title--xxl {
                padding-top: 3rem;
                padding-bottom: 2rem;
            }

            &.mad-title--xl {
                padding-top: 2.5rem;
                padding-bottom: 1.75rem;
            }

            &.mad-title--l {
                padding-top: 2.25rem;
                padding-bottom: 1.5rem;
            }

            &.mad-title--ml {
                padding-top: 2rem;
                padding-bottom: 1.375rem;
            }

            &.mad-title--m {
                padding-top: 1.75rem;
                padding-bottom: 1.25rem;
            }

            &.mad-title--sm {
                padding-top: 1.625rem;
                padding-bottom: 1.125rem;
            }

            &.mad-title--s {
                padding-top: 1.5rem;
                padding-bottom: 1rem;
            }

            &.mad-title--xs {
                padding-top: 1.375rem;
                padding-bottom: 0.875rem;
            }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            @include typography.mad-title;

            margin: 0;
        }

        h1 {
            @extend .mad-title, .mad-title--xxl;
        }

        h2 {
            @extend .mad-title, .mad-title--xl;
        }

        h3 {
            @extend .mad-title, .mad-title--l;
        }

        h4,
        h5,
        h6 {
            @extend .mad-title, .mad-title--m;
        }

        .mad-text {
            &.mad-text--l {
                padding-bottom: 1.5rem;
            }

            &.mad-text--ml {
                padding-bottom: 1.375rem;
            }

            &.mad-text--m {
                padding-bottom: 1.25rem;
            }

            &.mad-text--s {
                padding-bottom: 1.125rem;
            }

            &.mad-text--xs {
                padding-bottom: 1rem;
            }
        }

        p {
            @include typography.mad-text('s');

            margin: 0;
        }
    }

    .mad--tabular {
        font-variant-numeric: tabular-nums;
    }

    .mad--pre {
        white-space: pre;
    }

    .mad--strong {
        font-weight: var(--mad-text__weight--bold);
    }
}
