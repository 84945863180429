@keyframes error-highlight-without-left-border {
    0% {
        border-color: var(--mad-danger-550);
        border-left-color: inherit;
    }

    100% {
        border-color: inherit;
    }
}

@keyframes error-highlight {
    0% {
        border-color: var(--mad-danger-550);
        border-left-color: inherit;
    }

    100% {
        border-color: inherit;
    }
}

@keyframes sfxLoadingCircle {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes mad-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes mad-pulse {
    0% {
        opacity: 0.5;
        transform: scale(1);
    }

    50%,
    100% {
        opacity: 0;
        transform: scale(2);
    }
}
