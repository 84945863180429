@use '../core/components/button';
@use '../core/components/icon';
@use '../core/functions';
@use '../core/utils';
@use '../core/typography';

.mad {
    .mad-input {
        --mad-default-border-color: transparent;
        --mad-default-background-color: var(--mad-neutral-d-200);

        --mad-hover-icon-color: var(--mad-neutral-d-600);
        --mad-hover-unit-color: var(--mad-neutral-d-700);
        --mad-hover-border-color: var(--mad-neutral-d-300);

        --mad-focus-unit-color: var(--mad-neutral-d-700);
        --mad-focus-border-color: var(--mad-primary-500);

        --mad-action-color: var(--mad-neutral-d-400);
        --mad-action-opacity: 0.25;
        --mad-background-color: var(--mad-default-background-color);
        --mad-border-color: var(--mad-default-border-color);
        --mad-icon-color: var(--mad-neutral-d-500);
        --mad-text-weight: 600;
        --mad-unit-color: var(--mad-neutral-d-600);

        --mad-underline-color: var(--mad-neutral-d-300);
        --mad-underline-color--empty: var(--mad-neutral-d-800);

        display: flex;
        justify-content: stretch;
        align-items: center;
        position: relative;

        border: 2px solid var(--mad-border-color);

        border-radius: functions.mad-size(4);
        background-color: var(--mad-background-color);

        &.mad-input--number {
            .mad-input__input {
                text-align: right;
            }
        }

        &.mad--empty {
            --mad-underline-color: var(--mad-underline-color--empty);
        }

        &.mad--focus {
            --mad-icon-color: var(--mad-primary__color);
            --mad-unit-color: var(--mad-focus-unit-color);
            --mad-border-color: var(--mad-focus-border-color);
            --mad-action-opacity: 1;

            @include utils.mad-focus();
            box-shadow: unset;
        }

        &.mad--error:not(.mad--focus) {
            --mad-border-color: var(--mad-danger-500);
        }

        &.mad--hover:not(.mad--focus):not(.mad--readonly):not(.mad--disabled) {
            --mad-icon-color: var(--mad-hover-icon-color);
            --mad-unit-color: var(--mad-hover-unit-color);
            --mad-border-color: var(--mad-hover-border-color);

            transition-duration: 0s;

            .mad-input__input {
                transition-duration: 0s;
            }

            .mad-input__icon {
                transition-duration: 0s;
            }
        }

        &.mad--readonly {
            --mad-border-color: var(--mad-neutral-d-300);
            --mad-background-color: transparent;
        }

        &.mad--disabled {
            opacity: 0.5 !important;
        }
    }

    .mad-input__icon {
        @include icon.mad-icon-size(sm);

        color: var(--mad-icon-color);
        display: flex;
        flex: 0 0 auto;
        width: functions.mad-size(32);
        height: 100%;

        transition: all 0.2s ease-out;

        + .mad-input__pre-unit {
            padding-left: functions.mad-size(4);
        }
    }

    .mad-input__pre-unit {
        flex: 0 0 auto;
        padding-left: functions.mad-size(8);
    }

    .mad-input__input {
        @include typography.mad-text;
        @include typography.mad-text-size('m');

        flex: 1 1 auto;
        height: functions.mad-size(32);
        appearance: none;
        background-color: transparent;
        outline: none;
        padding: 0 functions.mad-size(8);
        width: 100%;
        min-width: 0;
        font-weight: 600;
        border: none;
        transition: all 0.2s ease-out;
    }

    .mad-input__unit {
        flex: 0 0 auto;
        padding-right: functions.mad-size(8);
    }

    .mad-input__after {
        position: absolute;
        display: flex;
        right: -#{functions.mad-size(12)};
        flex: 0 0 auto;
        transform: translateX(100%);

        .mad-icon-button {
            @include button.mad-icon-button-size(sm);
        }
    }

    .mad-input__action {
        --mad-opacity: var(--mad-action-opacity);
        --mad-color: var(--mad-action-color);

        color: var(--mad-color);
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 auto;
        width: functions.mad-size(32);
        height: 100%;

        transition: all 0.2s ease-out;

        .mad-icon-button {
            @include button.mad-icon-button-size('s');

            opacity: var(--mad-opacity);

            &:hover,
            &:focus {
                --mad-opacity: 1;
            }
        }
    }

    .mad-input__pre-unit,
    .mad-input__unit {
        @include typography.mad-text-size('m');

        color: var(--mad-unit-color);
        font-weight: 500;
    }

    .mad-input input,
    .mad-input textarea {
        &:disabled {
            @include utils.disabledControl;
        }
    }
}
