@mixin hypo-dossier__document-page-img {
    .hd-override-document-image {
        //Single document page <img>.
        box-shadow: var(--hd-document-img-box-shadow) !important;
        border-radius: unset;
    }
}

@mixin hypo-dossier__zoom-button {
    .hd-override-zoom-widget-wrapper {
        //Zoom button wrapper.
        border: none;
        width: 90px;
        cursor: pointer;
        background: transparent;
    }
}

@mixin hypo-dossier-svg-reset {
    svg {
        &:not([width]) {
            width: initial;
        }

        &:not([height]) {
            height: initial;
        }
    }
}

//Common styles for both dossier and preview components.
.hypo-dossier-list__wrapper,
.hypo-dossier-preview__wrapper {
    font-family: var(--mad__text-font);
    scrollbar-color: var(--mad-neutral-d-421) var(--mad-neutral-d-200);

    .hd-ant-col,
    .hd-ant-row,
    .ant-row,
    .ant-col {
        font-family: var(--mad__text-font);
    }

    .hd-webcomponent > * {
        //Needed for the sfx-loader to work.
        position: relative;
        background: var(--mad-neutral-d-000);
    }

    .hd-override-dossier-page-header-wrapper,
    .hd-override-preview-page-header-wrapper {
        //Toolbar top wrapper.
        padding: unset;

        .hd-override-dossier-page-toolbar-wrapper,
        .hd-override-detail-page-toolbar-wrapper {
            background: transparent;
            height: 40px;
            margin-bottom: unset;
            border-bottom: solid 1px var(--mad-neutral-d-275);
            padding-left: 5px;
            padding-right: 10px;
            gap: 8px;

            .hd-override-toolbar-button,
            .hd-override-detail-navbar-btn {
                //Toolbar buttons (no zoom button)
                margin-inline: unset !important;

                &:not(:disabled):not(.hd-ant-btn-disabled):hover {
                    box-shadow: 1px 3px 10px 0 rgba(0, 0, 0, 0.16);
                    color: var(--mad-primary-700) !important;
                    border-color: transparent !important;
                    background: transparent !important;
                }

                .hd-override-combine-pages-notification-bubble {
                    top: -16%;
                    font-size: 0.75rem;
                    padding: 1px 6px;
                }
            }

            .hd-override-detail-navbar-sorting-btn-wrapper {
                margin-left: unset !important;
            }
        }
    }

    @include hypo-dossier__zoom-button;
    @include hypo-dossier__document-page-img;
    @include hypo-dossier-svg-reset;
}

.hypo-dossier-list__wrapper {
    .hd-override-dossier-page-body-wrapper {
        background: var(--mad-neutral-d-200);
        padding: unset;

        .hd-override-view-scroll {
            padding: 0 5px 0 0;

            .hd-override-dossier-row {
                padding: unset !important;

                .hd-override-dnd-scroll-container {
                    --hd-document-container-border-radius: 6px;
                    scrollbar-width: thin;
                    padding: 20px 10px 20px 20px;

                    .hd-override-category-title {
                        cursor: pointer;
                        margin: unset;
                        background: var(--mad-neutral-d-000);
                        border-top-left-radius: var(--hd-document-container-border-radius);
                        border-top-right-radius: var(--hd-document-container-border-radius);
                        border: solid 1px var(--mad-neutral-d-300) !important;
                        font-size: 0.875rem;
                        font-weight: 600;
                        padding: 8px 12px;

                        &:not(:first-child) {
                            margin-top: 10px;
                        }

                        &.hd-override-category-title-collapsed {
                            border-bottom-left-radius: var(--hd-document-container-border-radius);
                            border-bottom-right-radius: var(--hd-document-container-border-radius);
                        }

                        &.hd-override-unrecognized-category {
                            background: var(--mad-danger-150) !important;
                        }

                        .hd-override-title-caret-icon-wrapper svg {
                            display: flex;
                            color: var(--mad-neutral-d-624) !important;
                        }
                    }

                    .hd-override-documents-container {
                        background: var(--mad-neutral-d-000);
                        border-bottom-left-radius: var(--hd-document-container-border-radius);
                        border-bottom-right-radius: var(--hd-document-container-border-radius);
                        border: 1px solid var(--mad-neutral-d-300);
                        border-top: unset;

                        .hd-override-no-documents-container {
                            padding: 20px 18px;
                            border: unset;
                        }

                        .hd-override-document-styled-row {
                            padding: 26px 18px;
                            border-bottom: solid 1px var(--mad-neutral-d-275);

                            &:last-child {
                                border-bottom: unset;
                            }

                            .hd-override-card-text {
                                font-size: 0.875rem;
                            }

                            .hd-override-recommendations-text-wrapper {
                                margin-left: unset;
                            }

                            .hd-override-undelete-button-wrapper {
                                .hd-override-undelete-button {
                                    //Undelete document or page button.
                                    height: 35px;
                                    background: var(--mad-primary-600);
                                    color: var(--mad-neutral-d-000) !important;
                                    border: unset;

                                    &:hover,
                                    &:active {
                                        background: var(--mad-primary-500) !important;
                                    }

                                    &:focus {
                                        background: var(--mad-primary-700) !important;
                                    }

                                    .hd-ant-btn-icon {
                                        svg {
                                            fill: var(--mad-neutral-d-000);
                                        }
                                    }
                                }
                            }

                            .hd-override-document-wrapper {
                                margin-top: 10px;
                                margin-left: unset;

                                .hd-override-document-images-container {
                                    gap: 20px;
                                }

                                .hd-override-document-image-wrapper {
                                    min-width: 50px;

                                    & > div:first-child {
                                        height: 100%;
                                    }
                                    .hd-override-document-image-container {
                                        display: flex;
                                        align-items: center;
                                        height: 100%;

                                        > div > div {
                                            height: 100%;
                                            width: 100%;
                                            align-items: center;
                                        }
                                    }
                                    .hd-override-document-image {
                                        --hd-document-img-box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
                                    }

                                    //.hd-override-document-image-checked { // A class added when <img> element is selected!
                                    //
                                    //}

                                    .hd-override-image-hover-menu-wrapper,  // A class added to the top right element wrapper displayed on <img> hover.
                                    .hd-override-image-hover-checkbox-wrapper {
                                        // A class added to the bottom right element wrapper displayed on <img> hover.
                                        left: unset;
                                        right: 10px;
                                        margin: 0;
                                        padding-right: 0;
                                        padding-bottom: 0;
                                    }

                                    --hd-override-image-hover-items-top-bottom: 5px;
                                    .hd-override-image-hover-menu-wrapper {
                                        top: var(--hd-override-image-hover-items-top-bottom);
                                        display: flex !important;
                                    }

                                    .hd-override-image-hover-checkbox-wrapper {
                                        top: unset;
                                        width: 100%;
                                        bottom: var(--hd-override-image-hover-items-top-bottom);
                                    }

                                    .hd-override-image-undelete-wrapper {
                                        top: unset;
                                        bottom: 0;

                                        .hd-override-undelete-button-wrapper {
                                            .hd-override-undelete-button {
                                                //Undelete page button.
                                                padding-left: unset !important;

                                                span:last-child {
                                                    //Button label.
                                                    flex: unset;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.hypo-dossier-preview__wrapper {
    container-type: inline-size;
    container-name: hypo-dossier-preview;

    .hd-override-detail-layout-wrapper {
        //Top wrapper grid element.
        @container hypo-dossier-preview (width > 900px) {
            grid-template-columns: 80% 20% !important;
        }
    }

    .hd-override-detail-max-view-wrapper,
    .hd-override-detail-list-view-wrapper {
        //Pages scrollable contents, left and right one.
        padding: unset;
    }

    .hd-override-detail-max-view-wrapper {
        //Left pages scrollable content
        background: var(--mad-neutral-d-200);
        padding-right: 5px;

        .hd-override-detail-max-view-inner-wrapper {
            //Left pages scrollable content - inner wrapper: This element wraps all of the pages elements
            scrollbar-width: thin;
            background: var(--mad-neutral-d-200);

            @container hypo-dossier-preview (width > 1150px) {
                padding-inline: 4cqi;
            }

            @container hypo-dossier-preview (width > 1350px) {
                padding-inline: 6cqi;
            }

            .hd-override-max-image-wrapper {
                &:first-child {
                    padding-top: 30px;
                }
            }
        }
    }

    .hd-override-detail-list-view-wrapper {
        //Right pages scrollable content
        background: var(--mad-neutral-d-345) !important;
        scrollbar-width: thin;

        .hd-override-detail-list-view-inner-wrapper {
            //Right pages scrollable content - inner wrapper. This element wraps all of the pages elements.
            padding-top: 30px;

            .hd-override-document-thumbnail-wrapper {
                .hd-override-document-image {
                    max-height: 180px !important;
                    max-width: 180px !important;

                    &.hd-override-document-image-checked {
                        border-width: 3px !important;
                    }
                }
            }
        }
    }
}

.hd-ant-popover {
    @include hypo-dossier-svg-reset;

    &.hd-override-context-menu-wrapper {
        .hd-ant-popover-arrow {
            display: none;
        }

        .hd-ant-popover-inner {
            padding: unset;

            .hd-ant-popover-inner-content {
                border-radius: 4px;
                overflow: auto;

                .hd-ant-btn-default {
                    border: unset;
                    margin-left: unset !important;
                    height: 34px;
                    padding-inline: 15px;

                    &:hover {
                        color: black !important;
                        background: var(--mad-primary-80) !important;

                        &:disabled {
                            color: rgba(0, 0, 0, 0.25) !important;
                            background: unset !important;
                        }
                    }
                }
            }
        }
    }
}

.hd-ant-modal {
    @include hypo-dossier-svg-reset;
}

.hd-override-sorting-dialog .hd-ant-modal-content {
    .hd-ant-modal-close {
        top: 16px;
    }

    .hd-ant-modal-header {
        background: white;
        margin: unset;

        @include hypo-dossier__zoom-button;
    }

    .hd-ant-modal-body {
        padding: 0 10px;
        background: var(--mad-neutral-d-200);

        .hd-override-sorting-dialog-body-wrapper {
            scrollbar-color: var(--mad-neutral-d-421) var(--mad-neutral-d-200);
            scrollbar-width: thin;
            gap: 10px;
            height: auto !important;
            max-height: 75vh; //HD height value
            overflow-y: auto !important;

            .hd-override-document-thumbnail-wrapper {
                margin: unset;
                height: fit-content;

                @include hypo-dossier__document-page-img;

                .hd-override-document-image-checked {
                    border-width: 3px !important;
                }
            }
        }
    }

    .hd-ant-modal-footer {
        margin: unset;
    }
}
