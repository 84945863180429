@use '../core/utils';

.mad {
    .mad-circular {
        border-radius: 999px;
    }

    .mad-no-selection {
        user-select: none;
    }

    .mad-no-appearance {
        -webkit-appearance: none;
        -moz-appearance: none;
    }

    .mad-spacer {
        flex: 1 1 auto;
    }

    .mad-middle {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mad-visually-hidden {
        position: absolute;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px);
        white-space: nowrap;
    }

    .mad-relative {
        position: relative;
    }

    .mad-absolute {
        --mad-offset: var(--mad-absolute__offset, 0);

        position: absolute;
        top: var(--mad-offset);
        right: var(--mad-offset);
        bottom: var(--mad-offset);
        left: var(--mad-offset);

        &.mad--top-left {
            right: unset;
            bottom: unset;
        }

        &.mad--top {
            right: unset;
            bottom: unset;
            left: 50%;
        }

        &.mad--top-right {
            bottom: unset;
            left: unset;
        }

        &.mad--right {
            top: 50%;
            bottom: unset;
            left: unset;
        }

        &.mad--bottom-right {
            top: unset;
            left: unset;
        }

        &.mad--bottom {
            top: unset;
            right: unset;
            left: 50%;
        }

        &.mad--bottom-left {
            top: unset;
            right: unset;
        }

        &.mad--left {
            top: 50%;
            right: unset;
            bottom: unset;
        }
    }

    .mad-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .mad-no-wrap {
        white-space: nowrap;
    }

    .mad-unset {
        @include utils.mad-unset();
    }

    .mad-revert {
        all: revert;
    }

    .mad-text-overflow {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .mad-no-overflow {
        overflow: hidden;
    }

    .mad-clickable {
        @include utils.mad-clickable();
    }

    .mad-fill,
    .mad-fit {
        display: flex;
        justify-content: stretch;
        align-items: stretch;
    }

    .mad-fill {
        > * {
            flex: 1 1 auto;
        }
    }

    .mad-fit {
        > * {
            flex: 1 0 auto;
        }
    }

    .mad-no-events {
        pointer-events: none;
    }

    .mad-full-width {
        width: 100%;
    }

    .mad-full-height {
        height: 100%;
    }
}
