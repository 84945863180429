:root {
    .mad {
        --offer-tranches-table-collapse-border-color: #dcdcdc;
        --border-width: 2px;
        --box-shadow-horizontal-width: 8px;
        --page-title-font-size: 20px;
        --inner-card-box-padding: 12px;
        --short-model-card-icon-size: 20px;
        --separator-margin: 20px;
        --offers-lane-min-height: 135px;
        --offer-documents-padding: 12px;
        --project-request-short-container-margin: 0.75rem;
        --project-page-container-margin: 1.5rem;
        --project-container-border-width: 1px;
        --project-container-border: solid var(--project-container-border-width) var(--mad-neutral-d-275);
        --slim-offer-hover-color: var(--mad-neutral-d-330);
        --hd-document-img-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }
}
