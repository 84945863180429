@use 'config';
@use 'functions';

@mixin mad-radius($size: 16px, $edge: null) {
    $value: #{functions.mad-size($size, config.$mad-radius-sizes)};

    @if not $edge {
        border-radius: $value;
    } @else if $edge == 'top' {
        border-top-left-radius: $value;
        border-top-right-radius: $value;
    } @else if $edge == 'right' {
        border-top-right-radius: $value;
        border-bottom-right-radius: $value;
    } @else if $edge == 'bottom' {
        border-bottom-right-radius: $value;
        border-bottom-left-radius: $value;
    } @else if $edge == 'left' {
        border-top-left-radius: $value;
        border-bottom-left-radius: $value;
    } @else if $edge == 'top-left' {
        border-top-left-radius: $value;
    } @else if $edge == 'top-right' {
        border-top-right-radius: $value;
    } @else if $edge == 'bottom-right' {
        border-bottom-right-radius: $value;
    } @else if $edge == 'bottom-left' {
        border-bottom-left-radius: $value;
    }
}
