@use 'colors.dark' as colors-dark;
@use 'colors.light' as colors-light;

.mad {
    .mad-color-primary {
        color: var(--mad-primary__color);
    }

    .mad-color-secondary {
        color: var(--mad-secondary__color);
    }

    @each $weight
        in (
            '000',
            '100',
            '200',
            '300',
            '400',
            '425',
            '450',
            '500',
            '525',
            '550',
            '575',
            '600',
            '625',
            '700',
            '701',
            '702',
            '800',
            '900',
            '999'
        )
    {
        .mad-color-neutral-d-#{$weight} {
            color: var(--mad-neutral-d-#{$weight});
        }

        .mad-background-color-neutral-d-#{$weight} {
            background-color: var(--mad-neutral-d-#{$weight});
        }
    }
}
