@use 'functions';
@use 'utils';

@mixin mad-list-vertical() {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-auto-flow: row;
}

@mixin mad-list-horizontal() {
    grid-template-columns: auto;
    grid-template-rows: 100%;
    grid-auto-flow: column;
}

@mixin mad-list($gap: 16px, $vertical: false) {
    --mad-gap: var(--mad-list__gap, #{functions.mad-size($gap)});

    gap: var(--mad-gap);

    &,
    &.mad--block {
        display: grid;
    }

    &.mad--inline {
        display: inline-grid;
    }

    @include utils.mad-default('.mad--horizontal', not $vertical) {
        @include mad-list-horizontal();
    }

    @include utils.mad-default('.mad--vertical', $vertical) {
        @include mad-list-vertical();
    }
}
