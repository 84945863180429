@use 'core/functions';
@use 'core/grid';

.mad {
    .mad-grid {
        @include grid.mad-grid();
    }

    @each $size in (10, 8, 6, 4, 2, 0) {
        .mad-grid-column-start--#{$size} {
            @include grid.mad-grid-column-start($size);
        }
    }

    @each $size in (12, 10, 8, 6, 4, 2) {
        .mad-grid-column-span--#{$size} {
            @include grid.mad-grid-column-span($size);
        }
    }

    .mad-grid {
        &.mad--inline {
            display: inline-grid;
        }

        @each $size in (32, 24, 20, 16, 12, 8, 4, 2, 0) {
            &.mad-grid-gap--#{$size} {
                --mad-gap: #{functions.mad-size($size)};
            }
        }

        &.mad-grid-align--start {
            align-items: start;
        }

        &.mad-grid-align--center {
            align-items: center;
        }

        &.mad-grid-align--end {
            align-items: end;
        }

        &.mad-grid-justify--start {
            justify-items: start;
        }

        &.mad-grid-justify--center {
            justify-items: center;
        }

        &.mad-grid-justify--end {
            justify-items: end;
        }
    }
}
