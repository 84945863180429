@use '../core/functions';

.mad {
    @each $prop in ('width', 'height') {
        @each $size in (32, 24, 20, 16, 12, 8, 4, 2) {
            .mad-#{$prop}-#{$size} {
                #{$prop}: functions.mad-size($size);
            }
        }
    }

    @each $prop in ('min-width', 'min-height') {
        @each $size in (32, 24, 20, 16, 12, 8, 4, 2) {
            .mad-#{$prop}-#{$size} {
                #{$prop}: functions.mad-size($size);
            }
        }
    }
}
