@use '../core/config';
@use '../core/margin';
@use 'sass:map';

.mad {
    @each $size in map.keys(config.$mad-margin-sizes) {
        .mad-margin-#{$size} {
            @include margin.mad-margin($size);
        }

        @each $side in top, right, bottom, left, vertical, horizontal {
            .mad-margin-#{$side}-#{$size} {
                @include margin.mad-margin($size, $side);
            }
        }
    }
}
