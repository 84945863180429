.mad.mad-theme--dark,
.mad-theme--dark {
    // non-standard colors
    --mad-neutral-d-425-rgb: 94, 94, 94;
    --mad-neutral-d-450-rgb: 110, 110, 110;
    --mad-neutral-d-525-rgb: 139, 139, 139;
    --mad-neutral-d-550-rgb: 141, 141, 141;
    --mad-neutral-d-575-rgb: 160, 160, 160;
    --mad-neutral-d-625-rgb: 183, 183, 183;
    --mad-neutral-d-701-rgb: 224, 224, 224;
    --mad-neutral-d-702-rgb: 230, 230, 230;
    --mad-neutral-d-999-rgb: 255, 255, 255;

    --mad-primary-575: rgb(42, 197, 255);
    --mad-primary-725: rgb(139, 223, 255);

    --mad-info-175: rgba(0, 69, 94);

    // https://www.colorbox.io/#steps=9#hue_start=0#hue_end=0#hue_curve=linear#sat_start=0#sat_end=0#sat_curve=linear#sat_rate=130#lum_start=10#lum_end=95#lum_curve=easeInOutSine#minor_steps_map=0
    --mad-neutral-d-000-rgb: 26, 26, 26;
    --mad-neutral-d-100-rgb: 30, 30, 30;
    --mad-neutral-d-200-rgb: 34, 34, 34;
    --mad-neutral-d-300-rgb: 55, 55, 55;
    --mad-neutral-d-400-rgb: 89, 89, 89;
    --mad-neutral-d-500-rgb: 133, 133, 133;
    --mad-neutral-d-600-rgb: 178, 178, 178;
    --mad-neutral-d-700-rgb: 213, 213, 213;
    --mad-neutral-d-800-rgb: 234, 234, 234;
    --mad-neutral-d-900-rgb: 242, 242, 242;

    @each $weight
        in (
            '000',
            '100',
            '200',
            '300',
            '400',
            '425',
            '450',
            '500',
            '525',
            '550',
            '575',
            '600',
            '625',
            '700',
            '701',
            '702',
            '800',
            '900',
            '999'
        )
    {
        --mad-neutral-d-#{$weight}: rgb(var(--mad-neutral-d-#{$weight}-rgb));
    }

    // https://www.colorbox.io/#steps=9#hue_start=196#hue_end=196#hue_curve=linear#sat_start=90#sat_end=2#sat_curve=easeInSine#sat_rate=130#lum_start=20#lum_end=100#lum_curve=easeInQuad#lock_hex=00aeef#minor_steps_map=0
    --mad-primary-050: rgb(0, 37, 51);
    --mad-primary-100: rgb(0, 57, 78);
    --mad-primary-200: rgb(0, 77, 105);
    --mad-primary-300: rgb(0, 113, 155);
    --mad-primary-400: rgb(0, 146, 201);
    --mad-primary-500: rgb(0, 174, 239);
    --mad-primary-600: rgb(57, 199, 252);
    --mad-primary-700: rgb(125, 222, 255);
    --mad-primary-800: rgb(192, 241, 255);
    --mad-primary-900: rgb(248, 253, 255);

    // https://www.colorbox.io/#steps=9#hue_start=180#hue_end=170#hue_curve=easeOutQuad#sat_start=90#sat_end=4#sat_curve=easeInSine#sat_rate=130#lum_start=20#lum_end=100#lum_curve=easeInQuad#lock_hex=88E996#minor_steps_map=0
    --mad-secondary-050: rgb(0, 51, 51);
    --mad-secondary-100: rgb(3, 72, 63);
    --mad-secondary-200: rgb(8, 94, 70);
    --mad-secondary-300: rgb(17, 137, 85);
    --mad-secondary-400: rgb(31, 177, 93);
    --mad-secondary-500: rgb(75, 210, 113);
    --mad-secondary-600: rgb(136, 233, 150);
    --mad-secondary-700: rgb(175, 245, 201);
    --mad-secondary-800: rgb(213, 252, 236);
    --mad-secondary-900: rgb(242, 255, 253);

    --mad-success-100: rgb(10, 96, 30);
    --mad-success-200: rgb(17, 116, 32);
    --mad-success-300: rgb(28, 144, 35);
    --mad-success-400: rgb(42, 172, 40);
    --mad-success-500: rgb(70, 201, 56);
    --mad-success-600: rgb(123, 222, 102);
    --mad-success-700: rgb(162, 238, 135);
    --mad-success-800: rgb(202, 249, 177);
    --mad-success-900: rgb(231, 252, 215);

    --mad-info-100: rgba(0, 37, 51);
    --mad-info-200: rgba(0, 78, 106);
    --mad-info-300: rgba(0, 116, 159);
    --mad-info-400: rgba(0, 148, 203);
    --mad-info-500: rgb(0, 174, 239);
    --mad-info-600: rgba(54, 199, 253);
    --mad-info-700: rgba(119, 222, 255);
    --mad-info-800: rgba(185, 242, 255);
    --mad-info-900: rgba(217, 248, 255);

    --mad-warning-100: rgb(122, 64, 0);
    --mad-warning-200: rgb(147, 82, 0);
    --mad-warning-300: rgb(183, 108, 0);
    --mad-warning-400: rgb(219, 137, 0);
    --mad-warning-500: rgb(255, 169, 0);
    --mad-warning-600: rgb(255, 197, 63);
    --mad-warning-700: rgb(255, 213, 102);
    --mad-warning-800: rgb(255, 231, 153);
    --mad-warning-900: rgb(255, 244, 204);

    --mad-danger-100: rgb(122, 8, 45);
    --mad-danger-200: rgb(147, 14, 46);
    --mad-danger-300: rgb(183, 22, 47);
    --mad-danger-400: rgb(219, 32, 45);
    --mad-danger-500: rgb(255, 49, 45);
    --mad-danger-600: rgb(255, 113, 97);
    --mad-danger-700: rgb(255, 152, 129);
    --mad-danger-800: rgb(255, 194, 171);
    --mad-danger-900: rgb(255, 228, 213);

    // --- color variables

    --mad-white-rgb: 255, 255, 255;
    --mad-black-rgb: 0, 0, 0;

    --mad-primary__color: var(--mad-primary-500);
    --mad-secondary__color: var(--mad-secondary-500);

    --mad-primary__color-rgb: 0, 174, 239;
    --mad-secondary__color-rgb: 75, 210, 113;

    --mad-title__color: var(--mad-neutral-d-800);
    --mad-text__color: var(--mad-neutral-d-900);

    --mad-focus__color: var(--mad-primary-200);
    --mad-hover__color: var(--mad-neutral-d-300);

    --mad-highlight__color: var(--mad-secondary-300);

    --mad-shadow__color-a: rgba(0, 0, 0, 0.1);
    --mad-shadow__color-b: rgba(0, 0, 0, 0.06);

    --mad-base-container-background-color: var(--mad-info-175);
}
