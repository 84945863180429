@forward 'core/utils' as core-utils-*;

@use 'core/normalize';
@use 'config';

@use 'reset';

@use 'colors';
@use 'typography';

@use 'auto-scroll';
@use 'flex';
@use 'grid';
@use 'group';
@use 'list';

@use 'utils';
@use 'components';
@use 'fragments';

@use 'animations';
@use 'variables';
@use 'overrides/nz-overrides';

.mad {
    font-family: var(--mad__text-font);
    font-weight: var(--mad__text-weight);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include normalize.mad-normalize();
}
